import { Table, Row, Col, Avatar, InputNumber, Typography, Space, Divider, Button, Popconfirm, Tag } from 'antd';
import { DeleteOutlined, WarningTwoTone } from '@ant-design/icons';
import { GroupBy, TableRow } from './ProjectBudgetPlanner.model';
import MoneyInput from '../shared/MoneyInput';
import { formatCurrency } from '../../common/utils';

type Props = {
  dataSource: TableRow[];
  viewMode: GroupBy;
  showRemainingColumns: boolean;
  onDesiredPercentageChanged: (newValue: any, role: string, userId: number, rate: number, rateType: string) => void;
  onDesiredHoursChanged: (newValue: any, role: string, userId: number, rate: number, rateType: string) => void;
  onDelete: (key: string) => void;
};

export const DataTable = ({ dataSource, viewMode, showRemainingColumns, onDesiredPercentageChanged, onDesiredHoursChanged, onDelete }: Props) => {
  const totals = dataSource.reduce(
    (acc, cur) => {
      acc.desiredPercentage += +cur.desiredPercentage;
      acc.desiredBudget += +cur.desiredBudget;
      acc.desiredHours += +cur.desiredHours;
      acc.totalCost += +cur.totalCost;
      acc.totalMinutes += +cur.totalMinutes;
      return acc;
    },
    { desiredPercentage: 0, desiredBudget: 0, desiredHours: 0, totalCost: 0, totalMinutes: 0 },
  );

  const usedHours = totals.totalMinutes / 60;

  return (
    <Table
      id="budget-planner"
      tableLayout="auto"
      bordered
      size="small"
      dataSource={dataSource}
      expandable={{
        defaultExpandAllRows: true,
        expandedRowKeys: dataSource.map((r: TableRow) => r.key),
        showExpandColumn: false,
      }}
      pagination={false}
      onRow={(record: TableRow) => ({
        style: {
          backgroundColor: record.isParent ? '#ECEFF3' : undefined,
        },
      })}
    >
      <Table.Column
        dataIndex="name"
        render={(_, row: TableRow, i) => (
          <Row justify="space-between">
            <Col style={{ paddingLeft: row.isParent ? undefined : '16px' }}>
              <Space>
                {row.entityType === 'member' && row.picture && <Avatar src={row.picture} size="small" />}
                <>
                  {row.isParent && row.name}
                  {!row.isParent && (viewMode === 'role' ? row.name : row.role)}
                </>
              </Space>
            </Col>
            <Col>
              {!row.isParent && row.rateType === 'monthly' && <Tag color="gold">Montly retainer</Tag>}
              {!row.isParent && row.rateType !== 'monthly' && row.rate > 0 && formatCurrency(row.rate)}
              {!row.isParent && row.rateType === 'monthly' && (
                <>
                  {formatCurrency(row.averageHourlyRate)}/h{' '}
                  {!row.averageHourlyRate && <WarningTwoTone twoToneColor="#f00" style={{ marginLeft: '5px' }} />}
                </>
              )}
            </Col>
          </Row>
        )}
      />

      <Table.ColumnGroup title="Desired %">
        <Table.Column
          dataIndex="desiredPercentage"
          title={
            <InputNumber
              style={{ width: '65px', color: totals.desiredPercentage !== 100 ? 'red' : undefined }}
              value={totals.desiredPercentage}
              formatter={value => `${value || 0}%`}
              bordered={false}
              readOnly={true}
            />
          }
          width="65px"
          render={(desiredPercentage, row: TableRow, i) => (
            <InputNumber
              style={{ width: '65px', color: row.isParent ? 'rgba(0,0,0,0.45)' : undefined }}
              controls={false}
              min={0}
              max={100}
              value={desiredPercentage}
              formatter={value => `${value}%`}
              parser={value => value!.replace('%', '')}
              bordered={!row.isParent}
              readOnly={row.isParent}
              disabled={!row.isParent && row.rateType === 'monthly' && !row.averageHourlyRate}
              onChange={(newValue: any) => onDesiredPercentageChanged(newValue, row.role!, row.userId!, row.rate!, row.rateType!)}
            />
          )}
        />
      </Table.ColumnGroup>

      <Table.ColumnGroup
        title={
          <>
            Hours
            <Space split={<Divider type="vertical" />} style={{ width: '100%' }}>
              <Typography.Text style={{ width: '70px', display: 'block' }} type="secondary">
                Desired
              </Typography.Text>
              <Typography.Text style={{ width: '70px', display: 'block' }} type="secondary">
                Used
              </Typography.Text>
              <Typography.Text style={{ width: '70px', display: 'block' }} type="secondary">
                Remaining
              </Typography.Text>
            </Space>
          </>
        }
      >
        <Table.Column
          dataIndex="desiredHours"
          title={
            <Space split={<Divider type="vertical" />}>
              <InputNumber style={{ width: '70px' }} value={totals.desiredHours.toFixed(2)} bordered={false} readOnly={true} />
              <InputNumber style={{ width: '70px' }} value={usedHours.toFixed(2)} bordered={false} readOnly={true} />
              {showRemainingColumns ? (
                <InputNumber style={{ width: '70px' }} value={(totals.desiredHours - usedHours).toFixed(2)} bordered={false} readOnly={true} />
              ) : (
                <Typography.Text style={{ width: '70px', display: 'block' }} type="secondary" italic>
                  ?
                </Typography.Text>
              )}
            </Space>
          }
          width="150px"
          render={(desiredHours, row: TableRow) => {
            const usedHours = (row.totalMinutes || 0) / 60;
            return (
              <Space split={<Divider type="vertical" />}>
                <InputNumber
                  style={{ width: '70px', color: row.isParent ? 'rgba(0,0,0,0.45)' : undefined }}
                  controls={false}
                  min={0}
                  value={desiredHours}
                  bordered={!row.isParent}
                  readOnly={row.isParent}
                  disabled={!row.isParent && row.rateType === 'monthly' && !row.averageHourlyRate}
                  onChange={(newValue: any) => onDesiredHoursChanged(newValue, row.role!, row.userId!, row.rate!, row.rateType!)}
                />
                <InputNumber
                  style={{ width: '70px', color: row.isParent ? 'rgba(0,0,0,0.45)' : undefined }}
                  controls={false}
                  value={usedHours.toFixed(2)}
                  bordered={false}
                  readOnly={true}
                />
                {showRemainingColumns ? (
                  <InputNumber
                    style={{ width: '70px', color: row.isParent ? 'rgba(0,0,0,0.45)' : usedHours > desiredHours ? 'red' : undefined }}
                    controls={false}
                    value={(desiredHours - usedHours)?.toFixed(2)}
                    bordered={false}
                    readOnly={true}
                  />
                ) : (
                  <Typography.Text style={{ width: '70px', display: 'block' }} type="secondary" italic>
                    ?
                  </Typography.Text>
                )}
              </Space>
            );
          }}
        />
      </Table.ColumnGroup>
      <Table.ColumnGroup
        title={
          <>
            Budget
            <Space split={<Divider type="vertical" />} style={{ width: '100%' }}>
              <Typography.Text style={{ width: '90px', display: 'block' }} type="secondary">
                Desired
              </Typography.Text>
              <Typography.Text style={{ width: '90px', display: 'block' }} type="secondary">
                Used
              </Typography.Text>
              <Typography.Text style={{ width: '90px', display: 'block' }} type="secondary">
                Remaining
              </Typography.Text>
            </Space>
          </>
        }
      >
        <Table.Column
          dataIndex="desiredBudget"
          title={
            <Space split={<Divider type="vertical" />}>
              <MoneyInput style={{ width: '90px' }} value={totals.desiredBudget.toFixed(2)} bordered={false} readOnly={true} />
              <MoneyInput style={{ width: '90px' }} value={totals.totalCost.toFixed(2)} bordered={false} readOnly={true} />
              {showRemainingColumns ? (
                <MoneyInput style={{ width: '90px' }} value={(totals.desiredBudget - totals.totalCost).toFixed(2)} bordered={false} readOnly={true} />
              ) : (
                <Typography.Text style={{ width: '70px', display: 'block' }} type="secondary" italic>
                  ?
                </Typography.Text>
              )}
            </Space>
          }
          width="150px"
          render={(desiredBudget, row: TableRow) => {
            const usedBudget = row.totalCost || 0;

            return (
              <Space split={<Divider type="vertical" />}>
                <MoneyInput
                  style={{ width: '90px', color: row.isParent ? 'rgba(0,0,0,0.45)' : undefined }}
                  value={desiredBudget}
                  readOnly={true}
                  bordered={false}
                />
                <MoneyInput
                  style={{ width: '90px', color: row.isParent ? 'rgba(0,0,0,0.45)' : undefined }}
                  controls={false}
                  value={usedBudget}
                  bordered={false}
                  readOnly={true}
                />
                {showRemainingColumns ? (
                  <MoneyInput
                    style={{ width: '90px', color: row.isParent ? 'rgba(0,0,0,0.45)' : usedBudget > desiredBudget ? 'red' : undefined }}
                    controls={false}
                    value={(desiredBudget - usedBudget)?.toFixed(2)}
                    bordered={false}
                    readOnly={true}
                  />
                ) : (
                  <Typography.Text style={{ width: '70px', display: 'block' }} type="secondary" italic>
                    ?
                  </Typography.Text>
                )}
              </Space>
            );
          }}
        />
      </Table.ColumnGroup>

      <Table.Column
        width="50px"
        dataIndex="action"
        render={(_, row: TableRow) =>
          !row.isParent && (
            <Popconfirm title="Are you sure?" onConfirm={() => onDelete(row.key)}>
              <Button disabled={row.totalMinutes > 0 || row.totalCost > 0} type="text" icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          )
        }
      />
    </Table>
  );
};
