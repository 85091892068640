import axios from 'axios';
import { User } from '../chat/entities';
import { convertSmileToEmoji } from '../chat/common/utils';
import { CHANNEL_TYPES } from '../chat/constants';
import { useSession } from '../dal';

export const axiosChatInstance = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API_BASE_URL,
});

export interface NewChannelData {
  name: string | null;
  members: User[];
}

const getChannels = searchParams =>
  axiosChatInstance({
    url: `/chat/channels?` + searchParams,
    method: 'GET',
  });

const createChannel = (chatId, channelName, values: NewChannelData) =>
  axiosChatInstance({
    url: `/chat/new-channel`,
    method: 'POST',
    data: {
      name: channelName,
      chatId,
      users: values.members,
      isNameEdited: !!values.name,
    },
  });

const renameChannel = (channelId, name) =>
  axiosChatInstance({
    url: `/chat/rename-channel`,
    method: 'POST',
    data: { id: channelId, name },
  });

const getUsers = chatId =>
  axiosChatInstance({
    url: `/users/chat/${chatId}`,
    method: 'GET',
  });

const getMessages = (channelId: string, chatId: string, part: number) =>
  axiosChatInstance({
    url: `/chat/${channelId}/messages?chatId=${chatId}&part=${part}`,
    method: 'GET',
  });

const reaction = (messageId, emoji) =>
  axiosChatInstance({
    url: `/chat/reaction/${messageId}`,
    method: 'POST',
    data: { emoji },
  });

const saveFile = (key, file: File) => {
  const formData = new FormData();
  formData.append('name', file, file.name);

  return axiosChatInstance({
    url: `/chat/save-file?key=${key}`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const createMessage = (
  isCreatingNewChannel,
  idIfCreatinNewChannel,
  channel,
  pValue,
  replyTo,
  channelName,
  chatId,
  isGlobal,
  members,
  attachedFiles,
) =>
  axiosChatInstance({
    url: `/chat/${isCreatingNewChannel ? idIfCreatinNewChannel : channel}/message`,
    method: 'POST',
    data: {
      text: convertSmileToEmoji(pValue),
      replyToId: isCreatingNewChannel ? null : replyTo?.id,
      createChannel: channelName,
      chatId: chatId,
      users: isGlobal ? null : members,
      type: isGlobal ? CHANNEL_TYPES.GLOBAL : null,
      files: attachedFiles.map(data => `${data.file.name}&${data.file.type}&${data.filterKey}&${data.file.size}`),
    },
  }).then(() =>
    axios.post(`/chat/${channel}/messages`, {
      text: convertSmileToEmoji(pValue),
      replyToId: isCreatingNewChannel ? null : replyTo?.id,
      createChannel: channelName,
      chatId: chatId,
      users: isGlobal ? null : members,
      type: isGlobal ? CHANNEL_TYPES.GLOBAL : null,
      files: attachedFiles.map(data => `${data.file.name}&${data.file.type}&${data.filterKey}&${data.file.size}`),
    }),
  );

const messageAction = data =>
  axiosChatInstance({
    url: `/chat/message/action`,
    method: 'POST',
    data,
  });

const readMessage = (messageId: string, manual: boolean) =>
  axiosChatInstance({
    url: `/chat/read-message`,
    method: 'PUT',
    data: { id: messageId, manual },
  });

const unreadMessage = messageId =>
  axiosChatInstance({
    url: `/chat/unread-message`,
    method: 'PUT',
    data: { id: messageId },
  });

const readAllMessages = channelId =>
  axiosChatInstance({
    url: `/chat/read-all-messages`,
    method: 'PUT',
    data: { channelId },
  });

const addMembers = (channelId, userId) =>
  axiosChatInstance({
    url: `/chat/${channelId}/add-members`,
    method: 'POST',
    data: { userId },
  });

const removeMember = (channelId, userId) =>
  axiosChatInstance({
    url: `/chat/${channelId}/remove-members`,
    method: 'POST',
    data: { userId },
  });

const getLastDateUserVisitedChannel = channelId =>
  axiosChatInstance({
    url: `/chat/channel/${channelId}/last-date-user-visited-channel`,
    method: 'GET',
  });

const chatApi = {
  getChannels,
  createChannel,
  renameChannel,
  getUsers,
  getMessages,
  reaction,
  saveFile,
  createMessage,
  messageAction,
  readMessage,
  unreadMessage,
  readAllMessages,
  addMembers,
  removeMember,
  getLastDateUserVisitedChannel,
};

export function useChatApi() {
  const { session } = useSession();

  axiosChatInstance.defaults.headers.common['Token'] = session?.token;
  axiosChatInstance.defaults.headers.common['ChatId'] = process.env.REACT_APP_CHAT_ID;

  return chatApi;
}
