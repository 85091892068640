import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ChatChannel } from '../../chat/entities/chat';
import { User } from '../../chat/entities';

import { axiosBackend } from '../../api/backend';
import { receiveEvent } from './pusherEventsSlice';

interface ChannelsState {
  channels: ChatChannel[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ChannelsState = {
  channels: [],
  isLoading: false,
  error: null,
};

export const fetchChannels = createAsyncThunk<ChatChannel[]>('channels/fetchChannels', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosBackend.get('/chat/channels', {});
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to fetch channels');
  }
});

export const createChannel = createAsyncThunk<
  ChatChannel,
  {
    name: string;
    type?: string;
    projectId?: string;
    members?: User[];
  }
>('channel/createChannel', async (payload, { rejectWithValue }) => {
  const { name, projectId, members, type } = payload;

  try {
    const response = await axiosBackend.post('/chat/channels', {
      name,
      type,
      projectId,
      members,
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to create channel');
  }
});

const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchChannels.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchChannels.fulfilled, (state, action: PayloadAction<ChatChannel[]>) => {
        state.isLoading = false;
        state.channels = action.payload;
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Error loading channels';
      })
      .addCase(createChannel.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createChannel.fulfilled, (state, action: PayloadAction<ChatChannel>) => {
        state.isLoading = false;
        state.channels.push(action.payload);
      })
      .addCase(createChannel.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to create channel';
      })
      .addCase(receiveEvent, (state, action) => {
        const { eventName, data } = action.payload;
        if (['chat:new-channel', 'user:added-to-channel'].includes(eventName)) {
          state.channels.push(data);
        }

        if (eventName === 'chat:new-member') {
          state.channels = state.channels.map(channel =>
            channel.id === data.channelId ? { ...channel, members: [...channel.members, data.user.user] } : channel,
          );
        }
      });
  },
});

export const selectChannels = (state: RootState) => state.channelReducer.channels;
export const selectChannelById = (state: RootState, id: string) => state.channelReducer.channels[id];

export default channelsSlice.reducer;
