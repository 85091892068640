import { Moment } from 'moment';
import { KeyedMutator } from 'swr';
import { BillingAddress, Invoice } from './Invoice';

export enum Roles {
  ADMIN = 'admin',
  FINANCES = 'finances',
  MANAGER = 'manager',
  CLIENT = 'client',
  USER = 'user',
  SALES = 'sales',
}

export interface IProjectRoles {
  title: string;
  prettified: string;
  color: string;
}

export const ProjectRoles: IProjectRoles[] = [
  { title: 'member', prettified: 'Member', color: 'gold' },
  { title: 'manager', prettified: 'Project Manager', color: 'purple' },
  { title: 'designer', prettified: 'Designer', color: 'blue' },
  { title: 'developer', prettified: 'Backend Developer', color: 'green' },
  { title: 'frontend_developer', prettified: 'Frontend Developer', color: 'magenta' },
  { title: 'account_executive', prettified: 'Account Executive', color: 'pink' },
  { title: 'financial_executive', prettified: 'Financial Executive', color: 'yellow' },
  { title: 'creative_director', prettified: 'Creative Director', color: 'geekblue' },
  { title: 'graphic_designer', prettified: 'Logo / Graphic Designer', color: 'lime' },
  { title: 'content_writer', prettified: 'Content Writer', color: 'orange' },
  { title: 'photographer', prettified: 'Photographer', color: 'cyan' },
  { title: 'consultant', prettified: 'Consultant', color: 'volcano' },
];

export interface FetchResult {
  isLoading: boolean;
  isError: boolean;
  mutate: KeyedMutator<any>;
}

export interface Project {
  id: string;
  autoCode: string;
  name: string;
  manager: string;
  members: ProjectMember[];
  phase: string;
  category: string;
  description: string;
  status: ProjectStatus;
  isPrivate: boolean;
  finance?: ProjectFinance;
  externalXeroId: string;
  externalClickUpListId: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  warrantyEndDate?: Date | null;
  estimatedEndDate?: Date | null;
  contractEndDate?: Date | null;
  client: Client | null;
  portfolioLinks?: Link[];
  hosting?: boolean | null;
  industry?: string[];
  isVisibleToClient: boolean;
  type: string;
  internalChatId?: string;
  clientChatId?: string;
}

export interface ProjectMember {
  id: string;
  project: Project;
  role: string;
  user: User;
  userId: number;
}

export interface Presentation {
  id: string;
  key: string;
  name: string;
  login?: string;
  password?: string;
  data: any;
  templateName: string;
  templateVariables: any[];
  PortfolioItems: any[];
  ProjectTeam: any[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ExternalLink {
  link: string;
  label: string;
  isVisibleToClient: boolean;
}

export interface Commission {
  id: string;
  value: number;
  userId: number;
  projectId: string;
  email?: string;
  userName?: string;
  projectName?: string;
  projectCode?: string;
  clientName?: string;
  picture?: string;
}

export interface UserRates {
  id?: number;
  user?: User;
  effectiveDate: Date | null | undefined | Moment | string;
  rateType: string | null;
  rateValue: number | null;
  averageHourlyRate: number | null;
  allowUrgentRate: boolean | null;
  urgentRateValue: number | null;
  rateComment: string | null;
  validFrom: string | null;
  validTo: string | null;
}

export interface Comment {
  id: string;
  text: string;
  threadId: string;
  createdAt: Date;
  updatedAt: Date;
  author: User;
}

export interface User {
  id: number;
  email: string;
  name: string;
  picture: string;
  title: string | null;
  role: string | null;
  bio: string | null;
  monthOfBirth: string | null;
  dayOfBirth: number | null;
  phone: string | null;
  links: ExternalLink[];
  roles: Array<string>;
  positions: Array<string>;
  projects: ProjectMember[];
  rateType: string | null;
  rateValue: number | null;
  slackUserId: string | null;
  requireTimesheet: boolean;
  allowVariableRate: boolean;
  allowUrgentRate: boolean | null;
  urgentRateValue: number | null;
  averageHourlyRate: number | null;
  allowFixedCost: boolean;
  createdAt: string;
  updatedAt: string;
  slackNotifications: boolean;
  emailNotifications: boolean;
  active: boolean;
  client: Client | null;
  xeroContactName: string;
  avatarColor?: string;
  initials?: string;
  assignedTasks: Task[];
}

export interface Client {
  id: string;
  autoCode: string;
  name: string;
  externalXeroId: string;
  projects: Project[];
  links: ExternalLink[];
  billingAddress: BillingAddress;
}

export interface BudgetItem {
  value: number;
  contractSignedDate?: Moment | Date | null;
  description?: string | null;
}

export interface ProjectFinance {
  budget: number;
  plannedHours: number;

  desiredBudget: number;
  averageHourlyRate: number;
  billableRate: number;

  // TODO: This will be removed
  payments: Invoice[];

  budgetDetails: BudgetItem[];
  paidTotal: number;
  remaining: number;
  calculation: any;
}

export const timesheetCategories = [
  'Back End Development',
  'Content Writing',
  'Design',
  'FILM / Photography',
  'Front End Development',
  'Illustration / Animation',
  'Misc',
  'PM',
  'Testing',
  'Legal',
  'Financial',
  'Research & Development',
  'Internal',
];

export interface TimesheetEntry {
  id: string;
  entryDate: Date;
  hours: number | 0;
  minutes: number | 0;
  customRate: number | 0;
  fixedCost: number | 0;
  description?: string;
  category: string;
  project: Project;
  task?: Task | null;
  user: User;
  year: number;
  month: number;
  week: string;
  amount: number | 0;
  cost: number | 0;
  isCommission: boolean;
}

export interface TimesheetWeek {
  id: string;
  year: number;
  week: string;
  user: User;
  submittedAt: Date;
  rejectedAt: Date | null;
  rejectedBy: User | null;
  approvedAt: Date | null;
  approvedBy: User | null;
  comment: string;
}

export interface TimesheetMonth {
  id: string;
  year: number;
  month: number;
  user: User;
  employeeName: string | null;
  submittedAt: Date;
  rejectedAt: Date | null;
  rejectedBy: User | null;
  approvedAt: Date | null;
  approvedBy: User | null;
  comment: string;
  billAmount: number;
  isPaid: boolean;
  isCleared: boolean;
  approval: string;
}

export type TimesheetTableEntry = Omit<TimesheetEntry, 'user'>;

export type CreateTimesheetEntry = Omit<TimesheetEntry, 'id' | 'project'> & { project: string; task: string; phase: string; spentType?: string };

export type CreateCommissionTimesheetEntry = {
  project: string;
  description: string;
  entryDate: Date;
  fixedCost: number | 0;
  user: number;
  isCommission: boolean;
  invoiceId?: string;
  spentType?: string;
};

export type Timesheet = Array<TimesheetEntry>;

export type Pagination = { prev: string; next: string };
export type TimesheetResponse = { timesheets: Timesheet; pagination: Pagination };

export type TimesheetSelfResponse = TimesheetResponse & { weekSubmition: TimesheetWeek | null; monthSubmition: TimesheetMonth | null; user: User };

export type Checklist = Array<ChecklistItem>;

export interface ChecklistItem {
  id: string;
  itemOrder: number;
  title: string;
  description: string;
  checked: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy?: User;
  user?: User;
}

export interface HomepageSetting {
  welcomeMessage: string;
  credits: string;
  url: string;
  dateFrom: Date | Moment | string;
}

export type HomepageSettings = HomepageSetting[];

export interface Approval {
  user: User;
  approval: ApprovalType;
}

export type ApprovalType = TimesheetWeek | TimesheetMonth | null;

export type ApprovalResponse = { approvals: Array<Approval>; pagination: Pagination };

export enum ApprovalStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  READY_TO_SUBMIT = 'ready_to_submit',
  AWAITING_APPROVAL = 'awaiting_approval',
  AWAITING_REAPPROVAL = 'awaiting_reapproval',
}

export interface SalesReportItem {
  clientCode: string;
  client: string;
  projectCode: string;
  project: string;
  contractDate: Date;
  budget: number;
}

export interface CashFlowReportItem {
  clientCode: string;
  client: string;
  projectCode: string;
  project: string;
  projectId: string;
  payment: number;
  paymentDate: Date;
  paymentYear: number;
  paymentMonth: number;
  paid: boolean;
}

export interface TimesheetReportItem {
  contactName: string;
  projectCode: string;
  clientName: string;
  projectName: string;
  category: string;
  userId: number;
  hours: number;
  rate: number;
  rateType: string;
  fixedCost: number;
  amount: number;
  isCommission: boolean;
}

export interface CommissionReportItem {
  key: string;
  billAmount: string;
  projectCode: string;
  clientName: string;
  clientId: string;
  projectName: string;
  commissionAmount: number;
  commissionPercent: number;
  personName: string;
  projectId: string;
  forMonth: string;
  userId: number;
  invoice: Invoice[];
}

export interface ProjectsReportItem {
  contactName: string;
  category: string;
  rate: number;
  projectCode: string;
  clientName: string;
  clientId: string;
  projectName: string;
  projectId: string;
  projectType: string;
  year: number;
  month: number;
  hours: number;
  amountWCom: number;
  amountExCom: number;
  fixedCost: number;
  isInternal: boolean;
  allTimeProfit: number;
  invoiceNumber?: string;
  isActive?: boolean;
  due?: string;
  paidDate?: number;
  sentDate?: number;
  estimatedPayDate?: number;
  invoiceValue?: number;
  actualCost?: number;
  markupPercentage?: number;
  description?: string;
  invoiceId?: string;
}

export type TaskStatus = 'todo' | 'done';
export type TaskState = 'new' | 'in progress' | 'stuck' | 'finished' | null;
export interface Task {
  id: string;
  name: string;
  description: string;
  status: TaskStatus;
  state: TaskState;
  createdBy: User;
  assignees: User[] | null;
  project: Project | null;
  dueDate: Date | null;
  phase: TaskPhase | null;
  estimate: number | null;
  timeSpent: number;
  createdAt: Date;
  isVisibleToClient: boolean;
}

export interface UpdateTask {
  id: string;
  name: string;
  description: string;
  status: TaskStatus;
  createdBy: number;
  project: string;
  dueDate: Date | null;
  createdAt: Date;
}

export interface Notification {
  id: string;
  user: User;
  title: string;
  message: string;
  linkType: 'project' | 'tasks' | 'timesheet' | 'ticket' | null;
  entityId: string | null;
  isRead: boolean;
  createdAt: Date;
}

export type ProjectStatus = 'active' | 'draft' | 'finished';

export type TicketStatus = 'new' | 'estimated' | 'approved' | 'finished';

export interface Ticket {
  id: string;
  status: TicketStatus;
  name: string;
  description: string;
  submittedAt: Date | null;
  approvedAt: Date | null;
  endDate: Date | null; // desired | estimated
  hours: number | null;
  financiallyClosed: boolean;
  project: Project;
  createdBy: User;
  createdAt: Date;
}

export type TaskChangeType = 'created' | 'dueDate' | 'status' | 'state';
export interface TaskHistory {
  id: string;
  changeType: TaskChangeType;
  newValue: string | null;
  changedBy: User;
  task: Task;
  createdAt: string;
}

export interface TaskPhase {
  id: string;
  name: string;
  order: number;
  isFinished: boolean;
  startDate: Date | null;
  endDate: Date | null;
  project: Project;
  tasks?: Task[];
  estimate: number | null;
}

export type PhaseChangeType = 'created' | 'startDate' | 'endDate' | 'name' | 'isFinished';
export interface PhaseHistory {
  id: string;
  changeType: PhaseChangeType;
  newValue: string | null;
  changedBy: User;
  phase: TaskPhase;
  createdAt: string;
}

export interface Link {
  id: string;
  linkType: string;
  url: string;
  isVisibleToClient: boolean;
  project: Project;
}

export type GroupByType = 'category' | 'phaseName' | '';

export type BillableInfo = {
  billableHours: number;
  billableRate: number;
  billableTotal: number;
};

export type DataItem = {
  key: string;
  phaseName?: string;
  category?: string;
  userName?: string;
  userId?: number;
  ratePerHour?: number;
  rateType?: string;
  totalMinutes?: number | string;
  fixedCost?: number;
  isCommission?: boolean;
  totalCost?: number;
  role?: string;
  billableHours?: number;
  billableRate?: number;
  billableTotal?: number;
  fixedBillableTotal?: number;
  lineItemName?: string;
  isLineItem?: boolean;
  isGroup?: boolean;
  type?: string;
};

export type GroupedDataItem = {
  key: string;
  totalCost?: number;
  totalMinutes?: number;
  billableHours?: number;
  billableRate?: number;
  billableTotal?: number;
  fixedCost?: number;
  fixedBillableTotal?: number;
  isGroup: true;
  type: string;
} & Partial<DataItem>;
