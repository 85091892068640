import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'use-debounce';
import { Button, Divider, Empty, Spin } from 'antd';
import moment from 'moment';
import { DownCircleOutlined } from '@ant-design/icons';
import { fetchMessages, setIsAtBottomReducer } from '../../../store/reducers/messagesSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { ChatMessage } from '../../entities/chat';
import Message from './Message';
import NoMessages from '../../static/images/no_messages.svg';
import { AMOUNT_OF_LOADED_MESSAGES } from '../../constants';
import { createActivity, fetchActivity } from '../../../store/reducers/activitySlice';
import { UserBrief } from '../../../dal';

import './Messages.css';

interface MessagesProps {
  loggedInUserId: number;
  users: UserBrief[];
  channelId: string;
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>;
}

const Messages = ({ loggedInUserId, users, channelId, setUser }: MessagesProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const { activity } = useAppSelector(state => state.activityReducer);
  const { messages: channelMessages, isLoading: messagesLoading } = useAppSelector(state => state.messagesReducer);

  const [hasScrolledToFirstUnread, setHasScrolledToFirstUnread] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [hasMoreOld, setHasMoreOld] = useState(true);
  const [hasMoreNew, setHasMoreNew] = useState(true);
  const [lastViewedMessageId, setLastViewedMessageId] = useState<string>('');

  const [actualLastReadMessageId, setActualLastReadMessageId] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);

  const [debouncedLastReadMessageId] = useDebounce(lastViewedMessageId, 1000);

  useEffect(() => {
    const loadActivityAndMessages = async (channelId: string) => {
      if (activity && activity.channelId === channelId) {
        if (!actualLastReadMessageId) {
          setActualLastReadMessageId(activity.lastReadMessageId);
        }

        return;
      }

      try {
        const { lastReadMessageId: fetchedLastReadMessageId } = await dispatch(fetchActivity({ channelId })).unwrap();
        if (fetchedLastReadMessageId) {
          setActualLastReadMessageId(fetchedLastReadMessageId);

          const fetchedMessages = await dispatch(fetchMessages({ channelId, lastReadMessageId: fetchedLastReadMessageId })).unwrap();

          if (fetchedMessages[fetchedMessages.length - 1].id === fetchedLastReadMessageId) {
            setHasMoreNew(false);
          }
        } else {
          const fetchedMessages = await dispatch(fetchMessages({ channelId })).unwrap();

          if (fetchedMessages.length < AMOUNT_OF_LOADED_MESSAGES) {
            setHasMoreOld(false);
          }

          if (fetchedMessages.length) {
            const lastMessageId = fetchedMessages[fetchedMessages.length - 1].id;

            await dispatch(
              createActivity({
                channelId,
                lastReadMessageId: lastMessageId,
              }),
            ).unwrap();

            setActualLastReadMessageId(lastMessageId);
          }
        }
      } catch (error) {
        console.error('Error fetching activity and messages:', error);
      }
    };

    if (channelId) {
      loadActivityAndMessages(channelId);
    }
  }, [activity, channelId, dispatch, actualLastReadMessageId]);
  const messages = useMemo(() => channelMessages[channelId] ?? [], [channelId, channelMessages]);

  const lastReadMessage = useMemo(() => messages?.find(({ id }) => id === actualLastReadMessageId), [messages, actualLastReadMessageId]);
  const topMessageId = useMemo(() => messages?.[0]?.id, [messages]);
  const bottomMessageId = useMemo(() => messages?.[messages.length - 1]?.id, [messages]);

  useEffect(() => {
    const scrollEl = scrollRef.current;
    if (!scrollEl) return;

    if (isAtBottom) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
      setHasScrolledToFirstUnread(true);
      return;
    }

    if (!messages.length || !lastReadMessage || hasScrolledToFirstUnread) return;

    const unreadMessageId = messages.find(m => m.createdAt > lastReadMessage?.createdAt)?.id;

    if (unreadMessageId) {
      const unreadMessageEl = scrollEl.querySelector(`[data-message-id="${unreadMessageId}"]`);
      const previousMessageEl = unreadMessageEl?.previousElementSibling;

      (previousMessageEl || unreadMessageEl)?.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    } else {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }

    setHasScrolledToFirstUnread(true);
  }, [hasScrolledToFirstUnread, isAtBottom, lastReadMessage, messages]);

  const scrollToBottom = async () => {
    if (!scrollRef.current) return;

    if (!hasMoreNew) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' });
      return;
    }

    try {
      const messages = await dispatch(fetchMessages({ channelId })).unwrap();

      if (messages.length) {
        if (!hasMoreOld && messages.length >= AMOUNT_OF_LOADED_MESSAGES) {
          setHasMoreOld(true);
        }

        await dispatch(
          createActivity({
            channelId,
            lastReadMessageId: messages[messages.length - 1].id,
          }),
        ).unwrap();

        setHasMoreNew(false);
        scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight });
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    if (debouncedLastReadMessageId) {
      dispatch(createActivity({ channelId, lastReadMessageId: debouncedLastReadMessageId })).unwrap();
    }
  }, [channelId, debouncedLastReadMessageId, dispatch]);

  const loadOlderMessages = useCallback(() => {
    if (topMessageId) {
      const prevScrollHeight = scrollRef.current?.scrollHeight || 0;

      dispatch(fetchMessages({ channelId, lastReadMessageId: topMessageId, direction: 'before' }))
        .unwrap()
        .then(data => {
          if (data.length < AMOUNT_OF_LOADED_MESSAGES) {
            setHasMoreOld(false);
          }

          if (scrollRef.current) {
            const newScrollHeight = scrollRef.current.scrollHeight;
            const scrollDiff = newScrollHeight - prevScrollHeight;

            if (scrollDiff > 0) {
              scrollRef.current.scrollTop += scrollDiff;
            }
          }
        });
    }
  }, [channelId, dispatch, topMessageId]);

  const loadNewerMessages = useCallback(() => {
    if (!bottomMessageId || !hasMoreNew || actualLastReadMessageId === messages[messages.length - 1].id) return;

    dispatch(fetchMessages({ channelId, lastReadMessageId: bottomMessageId, direction: 'after' }))
      .unwrap()
      .then(data => {
        if (data.length < AMOUNT_OF_LOADED_MESSAGES) {
          setHasMoreNew(false);
        }

        const newLastReadMessageId = messages[messages.length - 1].id;
        dispatch(createActivity({ channelId, lastReadMessageId: newLastReadMessageId })).unwrap();
      });
  }, [bottomMessageId, hasMoreNew, actualLastReadMessageId, messages, dispatch, channelId]);

  const handleScroll = useCallback(() => {
    if (!scrollRef.current) return;

    const { scrollTop, clientHeight, scrollHeight } = scrollRef.current;
    const scrollEl = scrollRef.current;

    const messageElements = Array.from(scrollEl.querySelectorAll('[data-message-id]')) as HTMLElement[];

    let lastVisibleMessageElement: HTMLElement | null = null;

    messageElements.forEach(el => {
      const rect = el.getBoundingClientRect();
      const containerRect = scrollEl.getBoundingClientRect();

      const isVisible = rect.bottom > containerRect.top && rect.top < containerRect.bottom;

      if (isVisible) {
        lastVisibleMessageElement = el;
      }
    });

    if (lastVisibleMessageElement !== null) {
      const messageId = (lastVisibleMessageElement as HTMLElement).getAttribute('data-message-id');

      if (messageId) {
        const lastVisibleMessage = messages.find(message => message.id === messageId);

        if (lastReadMessage && lastVisibleMessage && lastVisibleMessage.createdAt > lastReadMessage?.createdAt) {
          setLastViewedMessageId(messageId);
        }
      }
    }

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10;
    setIsAtBottom(isBottom);

    if (scrollTop <= 100 && hasMoreOld) {
      loadOlderMessages();
    }

    setShowScrollButton(scrollTop + clientHeight < scrollHeight - 100);
    dispatch(setIsAtBottomReducer({ channelId, isAtBottom }));
  }, [channelId, dispatch, hasMoreOld, isAtBottom, lastReadMessage, loadOlderMessages, messages]);

  const firstUnreadIndex = messages.findIndex(m => lastReadMessage && m.createdAt > lastReadMessage.createdAt && m.author?.id !== loggedInUserId);

  const renderDivider = useCallback(
    (message: ChatMessage, index: number) => {
      const prevMessage = index > 0 ? messages[index - 1] : null;

      const isNewDay = prevMessage ? new Date(message.createdAt).toDateString() !== new Date(prevMessage.createdAt).toDateString() : false;

      const isFirstUnread = index === firstUnreadIndex;

      if (!isFirstUnread && !isNewDay) {
        return null;
      }

      const dividerText = isNewDay ? formatMessageDate(new Date(message.createdAt)) : 'New';

      return (
        <Divider className={`chat-divider ${isFirstUnread ? 'new-divider' : ''}`}>
          <span className={`${isNewDay ? 'chat-divider-badge' : 'chat-divider-new'} ${isFirstUnread ? 'new-divider-badge' : ''}`}>{dividerText}</span>
        </Divider>
      );
    },
    [messages, firstUnreadIndex],
  );

  if (!messages.length && messagesLoading) {
    return <Spin />;
  }

  return (
    <div ref={scrollRef} style={{ height: `calc(100vh - 305px)`, overflow: 'auto' }} id="scrollableDiv" onScroll={handleScroll}>
      <InfiniteScroll
        key={messages.length}
        dataLength={messages.length}
        next={loadNewerMessages}
        hasMore={hasMoreNew}
        scrollableTarget="scrollableDiv"
        loader={false}
      >
        {messages.length ? (
          messages.map((message, index) => (
            <div key={message.id} data-message-id={message.id}>
              {renderDivider(message, index)}

              <Message message={message} channelId={channelId} loggedInUserId={loggedInUserId} users={users} setUser={setUser} />
            </div>
          ))
        ) : (
          <Empty image={NoMessages} description="No messages yet"></Empty>
        )}
      </InfiniteScroll>

      {showScrollButton && (
        <Button icon={<DownCircleOutlined style={{ fontSize: '30px' }} />} onClick={scrollToBottom} className="scroll-to-bottom-btn" />
      )}
    </div>
  );
};

export default Messages;

const formatMessageDate = (date: Date) => {
  const now = moment();
  const momentDate = moment(date);

  if (momentDate.isSame(now, 'day')) return 'Today';
  if (momentDate.isSame(now.clone().subtract(1, 'day'), 'day')) return 'Yesterday';

  return momentDate.format('dddd, MMMM Do');
};
