import moment from 'moment';
import TurndownService from 'turndown';
import { datePickerFormat } from '../../constants';
import { User } from '../entities';
import { ChatChannel, UserChannel } from '../entities/chat';

export function getFirstDayOfMonth() {
  return moment().startOf('month').format('YYYY-MM-DD');
}

export function isLastDayOfMonth(d: Date) {
  return new Date(d.getTime() + 86400000).getDate() === 1;
}

export function getLastSaturday() {
  const d = new Date();
  d.setDate(d.getDate() - ((d.getDay() + 6) % 7)); // Monday of this week
  const saturday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 2);
  return moment(saturday.toDateString()).format('YYYY-MM-DD');
}

export function formatDateTime(date: string | Date, format = 'lll'): string | undefined {
  return date ? moment(date).format(format) : undefined;
}

export function formatDate(date: string | Date, format = datePickerFormat): string | undefined {
  return date ? moment(date).format(format) : undefined;
}

export function formatCurrency(value: number): string {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value || 0);
}

export function getWeekDays(startDate?: string): string[] {
  let days: string[] = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(startDate).add(i, 'days').format(datePickerFormat));
  }

  return days;
}

export function displayNumberFromHours(timeString: string): number {
  if (typeof timeString === 'number') return timeString;

  const timeStringSplit = timeString.split(':');
  if (timeStringSplit.length === 1) {
    return Number(timeString);
  }
  const dec = parseInt(((Number(timeStringSplit[1]) / 6) * 10).toString(), 10);

  return parseFloat(parseInt(timeStringSplit[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}

export function displayHoursFromNumber(hours: number): string {
  const hour = Math.floor(hours);
  const decimal = hours - hour;
  const min = 1 / 60;
  const calculateDecimal = min * Math.round(decimal / min);
  let calculateMinute = Math.floor(calculateDecimal * 60).toString();
  if (calculateMinute.length < 2) {
    calculateMinute = '0' + calculateMinute;
  }

  return `${hour}:${calculateMinute}`;
}

export function nullableDataSorter(a?: Date | string | null | number, b?: Date | string | null | number): number {
  if (a && b && a instanceof Date && b instanceof Date) {
    return a.getTime() - b.getTime();
  } else if (a && b && typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  } else if (a && b && typeof a === 'number' && typeof b === 'number') {
    return a - b;
  } else if (a) {
    return -1;
  } else if (b) {
    return 1;
  }

  return 0;
}

export function isMemeberOfChat(chat: ChatChannel, member: User | User[] | string[]): UserChannel | undefined {
  if (Array.isArray(member)) {
    let result: UserChannel | undefined;
    member.forEach(user => {
      result = chat.members.find(cUser => cUser.user.id === user.id);
    });
    return result;
  }
  return chat.members.find(cUser => cUser.user.id === member.id);
}

const EMOJI_MAP = {
  ':)': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60a.png',
  ':D': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f601.png',
  ':(': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f61e.png',
  ':-)': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f603.png',
  ':-(': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f612.png',
  ':-/': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f621.png',
  ':-D': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60a.png',
  ';)': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f609.png',
  ';-)': 'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f609.png',
};

const lineBreak = `
`;

export const convertSmileToEmoji = (text: string) => {
  const turndownService = new TurndownService();
  turndownService.keep(['span']);
  turndownService.addRule('keep', {
    filter: ['img'],
    replacement: function (_, node) {
      return node.outerHTML;
    },
  });
  const markdownedText = turndownService.turndown(text).replaceAll(' ', ' ');
  const words = markdownedText && markdownedText.split(' ');
  const newText: string[] = [];
  if (words) {
    words.forEach((word: string) => {
      let w = word;
      if (word.includes(lineBreak) && word.split(lineBreak)[1] in EMOJI_MAP) {
        w = `${lineBreak}<img class="w-[20px]" src="${EMOJI_MAP[word.split(lineBreak)[1]]}" alt=""/>`;
      } else if (word in EMOJI_MAP) {
        w = `<img class="w-[20px]" src="${EMOJI_MAP[word]}" alt=""/>`;
      }
      newText.push(w);
    });
  }
  return newText.join(' ');
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytest', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function check(headers) {
  return (
    buffers,
    options = {
      offset: 0,
    },
  ) => headers.every((header, index) => header === buffers[options.offset + index]);
}

export const isPNG = check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
export const isJPEG = check([0xff, 0xd8, 0xff]);
