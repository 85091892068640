import { combineReducers, configureStore } from '@reduxjs/toolkit';
import messagesReducer from './reducers/messagesSlice';
import pusherEventsReducer from './reducers/pusherEventsSlice';
import notificationsReducer from './reducers/notificationsSlice';
import channelReducer from './reducers/channelsSlice';
import replyToReducer from './reducers/replyToSlice';
import activityReducer from './reducers/activitySlice';
import { pusherMiddleware } from './middlewares/pusherMiddleware';

const rootReducer = combineReducers({
  pusherEventsReducer,
  messagesReducer,
  notificationsReducer,
  channelReducer,
  replyToReducer,
  activityReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(pusherMiddleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
