import { Button, Card, Space, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { CloseOutlined } from '@ant-design/icons';
import { UserBrief } from '../../../dal';
import { DEFAULT_AVATAR } from '../../constants';

const { Text, Title } = Typography;

interface UserCardProps {
  onClose: () => void;
  user?: UserBrief;
}

const UserCard = ({ user, onClose }: UserCardProps) => {
  if (!user) {
    return null;
  }

  const userRoles = user.positions.join(' • ');

  return (
    <Card
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Text>Profile</Text>
          <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
        </Space>
      }
      style={{ width: 300 }}
      cover={<img alt="userPicture" src={user.picture || DEFAULT_AVATAR} />}
    >
      <Meta
        title={<Title level={3}>{user.name}</Title>}
        description={
          <Space size="small" direction="vertical">
            <Text>{user.title}</Text>
            <Text type="secondary">Roles: {userRoles}</Text>
          </Space>
        }
      />
    </Card>
  );
};

export default UserCard;
