import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import moment, { Moment } from 'moment';
import logo from '../../images/logo-black.jpg';
import { displayHoursFromNumber, formatCurrency } from '../../common/utils';
import { formInvoiceFromTimesheetData } from '../../utils';
import { datePickerFormat } from '../../constants';
import { Invoice } from '../../entities/Invoice';
import { ORDERED_KEYS } from '../../utils/constants';
import { DataItem, GroupByType, GroupedDataItem } from '../../entities';

type ProjectInvoiceProps = {
  invoice: Invoice;
  project: {
    projectCode: string;
    projectName: string;
  };
};

const styles = StyleSheet.create({
  page: { padding: 24, fontSize: 12 },
  section: { marginBottom: 24 },
  header: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 24 },
  logo: { height: 50 },
  title: { fontSize: 12, marginBottom: 12 },
  invoiceNumber: { fontSize: 18 },
  billingDetails: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 12 },
  tableHeader: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#000', paddingBottom: 5, marginBottom: 5 },
  tableRow: { flexDirection: 'row', paddingBottom: 5, marginBottom: 5 },
  tableSummary: { flexDirection: 'row', borderTopWidth: 1, borderBottomWidth: 1, marginTop: 20, marginBottom: 25, paddingVertical: 5 },
  textRightW25: { textAlign: 'left', width: '25%', paddingRight: 10 },
  textRightW30: { textAlign: 'left', width: '30%', paddingRight: 10 },
  textRightW15: { textAlign: 'right', width: '15%', paddingRight: 10 },
});

const formattedDate = (date: Moment | Date | string | null) => moment(date).format(datePickerFormat);

const PDFInvoice = ({ invoice, project }: ProjectInvoiceProps) => {
  const { autoCode, groupByPhase, dateRange, estimatedPayDate, due, details } = invoice;
  const { projectCode, projectName } = project;

  const projectTitle = `${invoice?.buyerDetails?.name} • ${projectName}`;

  const renderInvoiceHeader = () => (
    <View style={styles.header}>
      <Image style={styles.logo} src={logo} />

      <Text style={styles.invoiceNumber}>{autoCode}</Text>
    </View>
  );

  const renderBillingInfo = () => (
    <View style={styles.billingDetails}>
      {invoice?.sellerDetails && (
        <View>
          <Text>Invoice From:</Text>
          <Text>{invoice.sellerDetails.name}</Text>
          {ORDERED_KEYS.map(key => (
            <Text key={key}>{invoice.sellerDetails?.billingAddress[key]}</Text>
          ))}
        </View>
      )}
      {invoice?.buyerDetails && (
        <View>
          <Text>Invoice To:</Text>
          <Text>{invoice.buyerDetails.name}</Text>
          {ORDERED_KEYS.map(key => (
            <Text key={key}>{invoice.buyerDetails?.billingAddress[key]}</Text>
          ))}
        </View>
      )}
    </View>
  );

  const renderBillingDetails = () => (
    <View style={styles.billingDetails}>
      {dateRange && dateRange[0] && dateRange[1] && (
        <View>
          <Text>Billing Range:</Text>
          <Text>{dateRange.map(formattedDate).join(' - ')}</Text>
        </View>
      )}
      <View>
        <Text>Due Date:</Text>
        <Text>{estimatedPayDate ? formattedDate(estimatedPayDate) : 'N/A'}</Text>
      </View>
    </View>
  );

  const renderTableHeader = (title: string) => (
    <View style={styles.tableHeader}>
      <Text style={styles.textRightW25}>Name</Text>
      <Text style={styles.textRightW30}>{title}</Text>
      <Text style={styles.textRightW15}>Hours / Count</Text>
      <Text style={styles.textRightW15}>Rate / Price</Text>
      <Text style={styles.textRightW15}>Total</Text>
    </View>
  );

  const renderTableSummary = (totalBillableHours: number, totalBillableCount: number, totalBillableTotal: number) => (
    <View style={styles.tableSummary}>
      <Text style={styles.textRightW25}>Totals</Text>
      <Text style={styles.textRightW30}></Text>
      <Text style={styles.textRightW15}>
        {displayHoursFromNumber(totalBillableHours)}
        {totalBillableCount ? ' / ' : ''}
        {totalBillableCount || ''}
      </Text>
      <Text style={styles.textRightW15}></Text>
      <Text style={styles.textRightW15}>{formatCurrency(totalBillableTotal)}</Text>
    </View>
  );

  const renderTableRows = (groupedDetails: (DataItem | GroupedDataItem)[], groupingOption: GroupByType) =>
    groupedDetails.map(groupedDetail => (
      <View key={groupedDetail.key} style={styles.tableRow}>
        <Text style={styles.textRightW25}>{groupedDetail.lineItemName || 'Project services'}</Text>
        <Text style={styles.textRightW30}>
          {groupedDetail.key.endsWith(' (fixed cost)')
            ? groupedDetail.key.replace(' (fixed cost)', '')
            : groupedDetail.isLineItem
            ? groupingOption === 'category'
              ? groupedDetail.role
              : groupedDetail.phaseName
            : groupedDetail.key}
        </Text>
        <Text style={styles.textRightW15}>
          {groupedDetail.billableHours
            ? groupedDetail.fixedBillableTotal || (groupedDetail.isLineItem && groupedDetail.type === 'fixed')
              ? groupedDetail.billableHours
              : displayHoursFromNumber(groupedDetail.billableHours)
            : ''}
        </Text>
        <Text style={styles.textRightW15}>
          {groupedDetail.billableTotal && groupedDetail.billableHours
            ? formatCurrency(groupedDetail.billableTotal / groupedDetail.billableHours)
            : ''}
        </Text>
        <Text style={styles.textRightW15}>{formatCurrency(groupedDetail.fixedBillableTotal || groupedDetail.billableTotal || 0)}</Text>
      </View>
    ));

  const groupedDetailsByRole = formInvoiceFromTimesheetData(details, 'category').filter(
    detail =>
      (detail.isGroup && (detail.billableTotal || detail.fixedBillableTotal) && !detail.key.includes('Undefined')) ||
      (detail.isLineItem && (detail.category === 'Undefined' || detail.category === undefined)),
  );
  const groupedDetailsByPhase =
    groupByPhase &&
    formInvoiceFromTimesheetData(details, 'phaseName').filter(
      detail =>
        (detail.isGroup && (detail.billableTotal || detail.fixedBillableTotal) && !detail.key.includes('Undefined')) ||
        (detail.isLineItem && (detail.phaseName === 'Undefined' || detail.phaseName === undefined)),
    );

  const summarizedBillableHours = groupedDetailsByRole.reduce(
    (sum, { billableHours, fixedBillableTotal, isLineItem, type }) =>
      sum + (billableHours && ((!fixedBillableTotal && type !== 'fixed') || (isLineItem && type === 'hourly')) ? billableHours : 0),
    0,
  );
  const summarizedBillableCount = groupedDetailsByRole.reduce(
    (sum, { billableHours, fixedBillableTotal, isLineItem, type }) =>
      sum + (billableHours && (fixedBillableTotal || (isLineItem && type === 'fixed')) ? billableHours : 0),
    0,
  );
  const summarizedBillableTotal = groupedDetailsByRole.reduce((sum, { billableTotal }) => sum + (billableTotal ? billableTotal : 0), 0);
  const summarizedFixedBillableTotal = groupedDetailsByRole.reduce(
    (sum, { fixedBillableTotal }) => sum + (fixedBillableTotal ? fixedBillableTotal : 0),
    0,
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {renderInvoiceHeader()}
        <View style={styles.section}>
          <Text style={styles.title}>PROJECT NAME: {projectTitle}</Text>
          <Text style={styles.title}>PROJECT CODE: {projectCode}</Text>
          <Text style={styles.title}>BILL FOR: {due}</Text>

          {renderBillingInfo()}
        </View>
        {renderBillingDetails()}
        <View style={styles.section}>
          {renderTableHeader('Role')}
          {renderTableRows(groupedDetailsByRole, 'category')}
          {renderTableSummary(summarizedBillableHours, summarizedBillableCount, summarizedBillableTotal + summarizedFixedBillableTotal)}
          {groupByPhase && renderTableHeader('Phase')}
          {groupByPhase && groupedDetailsByPhase && renderTableRows(groupedDetailsByPhase, 'phaseName')}
        </View>
      </Page>
    </Document>
  );
};

export default PDFInvoice;
