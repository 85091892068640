import { useState } from 'react';
import { Comment, Avatar, Tooltip, Typography, Button, Popover, Tag, Mentions, Space } from 'antd';
import moment from 'moment';
import { SmileOutlined } from '@ant-design/icons';
import { ChatMessage } from '../../entities/chat';
import { UserBrief } from '../../../dal';
import UserHoverCard from './UserHoverCard';
import MessageMenuPopup from './MessageMenuPopup';
import { useAppDispatch } from '../../../hooks/redux';
import { deleteMessage, updateMessage, updateMessageReaction } from '../../../store/reducers/messagesSlice';
import EmojiPicker from 'emoji-picker-react';
import { CHAT_UPLOAD_BASE_URL, DEFAULT_AVATAR } from '../../constants';

const { Text, Link } = Typography;

interface MessageProps {
  message: ChatMessage;
  channelId: string;
  loggedInUserId: number;
  users: UserBrief[];
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>;
}

const Message = ({ message, channelId, loggedInUserId, users, setUser }: MessageProps) => {
  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const [showMessageReactionMenuPopup, setShowMessageReactionMenuPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(message.text);
  const [mentionOpen, setMentionOpen] = useState(false);

  const dispatch = useAppDispatch();

  const handleReaction = (emojiData: { emoji: string } | string) => {
    const emoji = typeof emojiData === 'string' ? emojiData : emojiData.emoji;

    const request = {
      messageId: message.id,
      channelId,
      emoji,
    };

    dispatch(updateMessageReaction(request));
    setShowMessageReactionMenuPopup(false);
  };

  const handleDelete = () => {
    const request = {
      messageId: message.id,
      channelId,
    };

    dispatch(deleteMessage(request));
  };

  const handleUpdate = () => {
    setIsEditing(true);
  };

  const handleSaveUpdate = () => {
    if (editedText.trim() === message.text) {
      setIsEditing(false);
      return;
    }

    dispatch(updateMessage({ messageId: message.id, channelId, text: editedText })).then(() => setIsEditing(false));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (mentionOpen) return;
      e.preventDefault();
      handleSaveUpdate();
    }
    if (e.key === 'Escape') {
      setMentionOpen(false);
      setIsEditing(false);
      setEditedText(message.text);
    }
  };

  const reactionsMap = message.reactions.reduce<Record<string, { count: number; users: string[] }>>((acc, reaction) => {
    const userName = users.find(u => u.id === reaction.userId)?.name || 'Unknown';

    if (!acc[reaction.emoji]) {
      acc[reaction.emoji] = { count: 0, users: [] };
    }
    acc[reaction.emoji].count++;
    acc[reaction.emoji].users.push(userName);
    return acc;
  }, {});

  const reactionButtons = Object.entries(reactionsMap).map(([emoji, { count, users }]) => {
    const isReactedByMe = message.reactions.some(r => r.userId === loggedInUserId && r.emoji === emoji);

    return (
      <Tooltip key={emoji} title={users.join(', ')}>
        <Tag color={isReactedByMe ? 'processing' : 'default'} onClick={() => handleReaction(emoji)} style={{ cursor: 'pointer' }}>
          {emoji} {count}
        </Tag>
      </Tooltip>
    );
  });

  const actions = message.reactions.length > 0 && !message.deletedAt && (
    <div>
      {reactionButtons}

      <Popover
        content={<EmojiPicker onEmojiClick={handleReaction} />}
        trigger="click"
        open={showMessageReactionMenuPopup}
        onOpenChange={setShowMessageReactionMenuPopup}
      >
        <Button shape="circle" size="small" icon={<SmileOutlined />} />
      </Popover>
    </div>
  );

  const mentionOptions = users.map(user => ({
    value: user.name,
    label: (
      <Space>
        <Avatar src={user.picture} style={{ marginRight: '6px' }} size="small" />
        <Typography.Text>{user.name}</Typography.Text>
      </Space>
    ),
  }));

  return (
    <div
      className="chat-message"
      onMouseEnter={() => setShowMenuPopup(true)}
      onMouseLeave={() => setShowMenuPopup(false)}
      style={{ backgroundColor: showMenuPopup ? 'rgba(0, 0, 0, 0.01)' : 'transparent' }}
    >
      <Comment
        actions={[actions]}
        author={<Text strong>{getNameFromEmail(message?.author?.email || '') || 'Sender name'}</Text>}
        avatar={<Avatar src={message?.author?.picture || DEFAULT_AVATAR} />}
        content={
          <div style={{ position: 'relative' }}>
            {isEditing ? (
              <div style={{ display: 'flex', gap: '8px' }}>
                <Mentions
                  value={editedText}
                  options={mentionOptions}
                  split="|"
                  onChange={setEditedText}
                  style={{ width: '100%' }}
                  autoFocus
                  onKeyDown={handleKeyDown}
                  onSelect={() => setMentionOpen(false)}
                  onFocus={() => setMentionOpen(false)}
                  onSearch={(_, prefix) => setMentionOpen(prefix === '@')}
                />
                <Button type="primary" size="small" onClick={handleSaveUpdate}>
                  Save
                </Button>
                <Button size="small" onClick={() => setIsEditing(false)}>
                  Cancell
                </Button>
              </div>
            ) : (
              <Text type={message.deletedAt && 'secondary'} style={{ whiteSpace: 'pre-wrap' }}>
                {renderMessageContent(message, loggedInUserId, users, setUser)}
              </Text>
            )}

            {showMenuPopup && !message.deletedAt && (
              <MessageMenuPopup
                isMyMessage={message.author.id === loggedInUserId}
                onReaction={handleReaction}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
            )}
          </div>
        }
        datetime={
          <Tooltip title={moment(message.createdAt).format('MMMM Do YYYY, h:mm:ss a')}>
            <Text type="secondary" style={{ fontSize: 12 }}>
              {moment(message.createdAt).format('H:mm')} | {moment(message.createdAt).fromNow()}
            </Text>
          </Tooltip>
        }
      />
    </div>
  );
};

export default Message;

const getNameFromEmail = (email: string) => email.split('@')[0].charAt(0).toUpperCase() + email.split('@')[0].slice(1).toLowerCase();

const renderTextWithMentions = (
  text: string,
  loggedInUserId: number,
  users: UserBrief[],
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>,
) => {
  const mentionRegex = /(\|?@[^|]+\|?)/g;
  const parts: (string | JSX.Element)[] = [];
  let lastIndex = 0;

  text.replace(mentionRegex, (match, _, offset) => {
    parts.push(text.slice(lastIndex, offset));

    const userName = match.replace(/\||@/g, '').trim();

    const user = users.find(u => u.name === userName);

    if (user) {
      parts.push(<UserHoverCard key={offset} user={user} loggedInUserId={loggedInUserId || 0} setUser={setUser} />);
    } else {
      parts.push(`@${userName}`);
    }

    lastIndex = offset + match.length;
    return match;
  });

  parts.push(text.slice(lastIndex));

  return <>{parts}</>;
};

const renderMessageContent = (
  message: ChatMessage,
  loggedInUserId: number,
  users: UserBrief[],
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>,
) => {
  const regex = new RegExp(`${CHAT_UPLOAD_BASE_URL}[^\\s]+`, 'g');

  const { text, isTextModified } = message;

  const parts = text.split(regex);
  const matches = text.match(regex) || [];

  return (
    <div>
      {parts.map((part, index) => (
        <span key={index}>
          {renderTextWithMentions(part, loggedInUserId, users, setUser)}

          {matches[index] && (
            <Link href={matches[index]} target="_blank" rel="noopener noreferrer">
              {matches[index].split('/').pop()}
            </Link>
          )}

          {isTextModified && (
            <Text style={{ fontSize: '0.7rem' }} type="secondary">
              {' '}
              (edited)
            </Text>
          )}
        </span>
      ))}
    </div>
  );
};
