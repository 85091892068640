import { Middleware } from '@reduxjs/toolkit';
import { fetchChannels } from '../reducers/channelsSlice';
import { receiveEvent } from '../reducers/pusherEventsSlice';
import { pusher } from '../../hooks/usePusherInit';
import { ChatMessage } from '../../chat/entities/chat';

let isPusherInitialized = false;

export const pusherMiddleware: Middleware =
  ({ dispatch }) =>
  next =>
  (action: any) => {
    const result = next(action);

    if (!isPusherInitialized) {
      isPusherInitialized = true;

      console.log('🔗 Connecting to Pusher and subscribing to system events...');

      const systemMessages = pusher.subscribe('private-system-messages');
      systemMessages.bind('chat:new-channel', (data: any) => {
        dispatch(receiveEvent({ eventName: 'chat:new-channel', data }));
        subscribeToChannel(dispatch, data.id);
      });

      pusher.user.bind('user:added-to-channel', (data: any) => {
        dispatch(receiveEvent({ eventName: 'user:added-to-channel', data }));
        subscribeToChannel(dispatch, data.id);
      });

      pusher.connection.bind('connected', () => console.log('✅ Pusher connected'));
      pusher.connection.bind('disconnected', () => console.log('❌ Pusher disconnected'));
      pusher.connection.bind('error', (error: any) => console.error('⚠️ Pusher connection error', error));
    }

    if (fetchChannels.fulfilled.match(action)) {
      const channels = action.payload;
      console.log('📡 Subscribing to existing channels:', channels);

      channels?.forEach(channel => {
        subscribeToChannel(dispatch, channel.id);
      });
    }

    return result;
  };

const subscribeToChannel = (dispatch: any, channelId: string) => {
  const pusherChannelId = `private-${channelId}`;
  let pusherChannel = pusher.channel(pusherChannelId);

  if (!pusherChannel) {
    console.log(`📡 Subscribing to channel ${pusherChannelId}`);
    pusherChannel = pusher.subscribe(pusherChannelId);
  }

  pusherChannel.unbind('chat:new-message');
  pusherChannel.bind('chat:new-message', (message: ChatMessage) => {
    console.log(`💬 New message in ${channelId}:`, message);
    dispatch(receiveEvent({ eventName: 'chat:new-message', data: { channelId, message } }));
  });

  pusherChannel.unbind('chat:new-member');
  pusherChannel.bind('chat:new-member', (data: any) => {
    console.log(`👤 New member in ${channelId}:`, data);
    dispatch(receiveEvent({ eventName: 'chat:new-member', data: { channelId, user: data.user } }));
  });

  pusherChannel.unbind('chat:new-reaction');
  pusherChannel.bind('chat:new-reaction', ({ userName, emoji, message }: { userName: string; emoji: null | string; message: ChatMessage }) => {
    console.log(`😃 New reaction in ${channelId}:`, emoji);
    dispatch(receiveEvent({ eventName: 'chat:new-reaction', data: { channelId, userName, emoji, message } }));
  });

  pusherChannel.unbind('chat:message-deleted');
  pusherChannel.bind('chat:message-deleted', (message: ChatMessage) => {
    console.log(`❌ Message deleted in ${channelId}:`, message);
    dispatch(receiveEvent({ eventName: 'chat:message-deleted', data: { channelId, message } }));
  });

  pusherChannel.unbind('chat:message-updated');
  pusherChannel.bind('chat:message-updated', (message: ChatMessage) => {
    console.log(`✏️ Message updated in ${channelId}:`, message);
    dispatch(receiveEvent({ eventName: 'chat:message-updated', data: { channelId, message } }));
  });
};
