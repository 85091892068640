import { Badge, Col, Row } from 'antd';
import { ChatChannel } from '../../../entities/chat';

interface ChannelMenuItemProps {
  channel: ChatChannel;
  selected: boolean;
  picture?: string;
  isCreatingNewChannel: boolean;
  countBadgePropName: string;
  onSelect: () => void;
  onCloseChannelList: () => void;
}

const ChannelMenuItem = ({
  channel,
  selected,
  picture,
  isCreatingNewChannel,
  countBadgePropName,
  onSelect,
  onCloseChannelList,
}: ChannelMenuItemProps) => {
  return (
    <div onClick={onSelect} className="cursor-pointer select-none mx-[8px]">
      <Row
        wrap={false}
        align="middle"
        onClick={onCloseChannelList}
        className={`project-item ${selected ? '!text-white selected-item' : '!text-[#818181]'}`}
      >
        <Col flex="auto" className="chat-tab-channel">
          {picture && <>{channel.members && <img src={picture} alt="" className="w-6 h-6 mr-1 rounded-full" />} </>}
          <span
            className={` whitespace-nowrap overflow-hidden text-ellipsis text-ellipsis font-[600] text-sm font-[Proxima Nova] ${
              selected && !isCreatingNewChannel ? ' !text-white' : '!text-[#818181]'
            } ${channel?.unread && !selected && '!text-black'}`}
          >
            {channel.name}
          </span>
        </Col>
        <Col flex="none" className="tab-badges">
          {!channel.unreadMentions && channel.manualUnread ? (
            <Badge dot={Boolean(channel.manualUnread)} style={{ transform: 'none', boxShadow: 'none' }} />
          ) : null}
          {channel[countBadgePropName] && channel[countBadgePropName] > 0 ? (
            <Badge count={channel[countBadgePropName]} style={{ transform: 'none', boxShadow: 'none' }} />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default ChannelMenuItem;
