import { useState, useEffect, useCallback } from 'react';
import { message, Space, Spin } from 'antd';
import InvoiceForm from './InvoiceForm';
import { Invoice } from '../../entities/Invoice';
import { formatInvoices, useProjectInvoices } from '../../dal/useProjectInvoices';
import { useProjectFinance } from '../../dal';
import { useNavigate } from 'react-router-dom';
import { ProjectWithClientName } from './ProjectFinance';

type Props = {
  project: ProjectWithClientName;
  invoiceId: string;
};

const InvoicePage = ({ project, invoiceId }: Props) => {
  const navigate = useNavigate();
  const { id: projectId } = project;

  const { mutate: mutateInvoice, findInvoiceById, deleteInvoice, saveInvoice } = useProjectInvoices(projectId);
  const { finance } = useProjectFinance(projectId);

  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (invoiceId === 'newInvoice') {
      const draftInvoice = {
        ordinal: 0,
        autoCode: '',
        invoiceNumber: '',
        value: 0,
        actualCost: 0,
        paid: false,
        description: '',
        clientNote: '',
        invoiceLink: '',
        clientInvoiceLink: '',
        due: '',
        estimatedPayDate: null,
        sentDate: null,
        paidDate: null,
        isActive: false,
        dateRange: null,
        details: [],
        markupPercentage: 0,
        groupByPhase: false,
      };

      setInvoice(draftInvoice);
      setLoading(false);
    } else {
      const fetchInvoice = async () => {
        try {
          const data = await findInvoiceById(projectId, invoiceId);
          setInvoice(formatInvoices([data.data])[0]);
        } catch (error: any) {
          console.error(error);

          if (error.response?.status === 500) {
            navigate(`/projects/${projectId}/invoices`);
            message.error(
              <span>
                Unfortunately, the invoice with ID: <strong>{invoiceId}</strong> could not be found.
              </span>,
            );
          }
        } finally {
          setLoading(false);
        }
      };

      fetchInvoice();
    }
  }, [findInvoiceById, navigate, invoiceId, projectId]);

  const onInvoiceDelete = useCallback(
    async (invoiceId: string) => {
      navigate(`/projects/${projectId}/invoices`);

      await deleteInvoice(invoiceId);
      await mutateInvoice();

      message.success('Your invoice has been deleted');
    },
    [deleteInvoice, mutateInvoice, navigate, projectId],
  );

  const onInvoiceFormFinish = useCallback(
    async (finishedInvoice: Invoice) => {
      setLoading(true);

      try {
        const invoiceToSave = finishedInvoice.id ? finishedInvoice : { ...finishedInvoice, id: invoice?.id };
        const savedInvoice = await saveInvoice(invoiceToSave);

        if (savedInvoice.data.createdAt) {
          setInvoice(formatInvoices([savedInvoice.data])[0]);
          navigate(`/projects/${projectId}/invoices/${savedInvoice.data.id}`);
        }

        await mutateInvoice();
        message.success('Your invoice has been saved');
      } finally {
        setLoading(false);
      }
    },
    [invoice, mutateInvoice, navigate, projectId, saveInvoice],
  );

  if (!finance || !projectId) {
    return <Spin />;
  }

  return (
    <Space
      style={{
        padding: '20px',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}
      direction="vertical"
    >
      <Spin size="large" spinning={loading}>
        {invoice && (
          <InvoiceForm
            invoice={invoice}
            projectBillableRate={finance.billableRate}
            project={project}
            onSubmit={onInvoiceFormFinish}
            onDelete={onInvoiceDelete}
          />
        )}
      </Spin>
    </Space>
  );
};

export default InvoicePage;
