import { Avatar, Typography, Space, Popover, Button } from 'antd';
import { UserBrief } from '../../../dal';

const { Title, Text } = Typography;

interface UserHoverCardProps {
  loggedInUserId: number;
  user?: UserBrief;
  setUser?: React.Dispatch<React.SetStateAction<UserBrief | null>>;
}

const UserHoverCard = ({ user, loggedInUserId, setUser }: UserHoverCardProps) => {
  if (!user) {
    return null;
  }

  const userRoles = user.positions.join(' • ');

  return (
    <Popover
      title={
        <Space style={{ padding: '12px' }}>
          <Avatar src={user.picture} size={80} />
          <Space direction="vertical" size="small" style={{ maxWidth: '250px' }}>
            <Title level={3}>{user.name}</Title>

            <Text strong>{user.title}</Text>
          </Space>
        </Space>
      }
      content={
        <Space direction="vertical" style={{ padding: '12px', maxWidth: '250px' }}>
          <Text type="secondary">Roles: {userRoles}</Text>
        </Space>
      }
      placement="top"
      trigger="hover"
    >
      <Button
        onClick={() => setUser && setUser(user)}
        size="small"
        style={{
          backgroundColor: loggedInUserId === user.id ? 'rgba(255, 235, 59, 0.5)' : 'rgba(88, 166, 255, 0.1)',
          color: '#58A6FF',
          borderRadius: 6,
          padding: '0 4px',
          cursor: 'pointer',
          border: 'none',
          textAlign: 'center',
        }}
        type="text"
      >
        @{user.name}
      </Button>
    </Popover>
  );
};

export default UserHoverCard;
