import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigCatProvider, PollingMode } from 'configcat-react';
import 'react-quill/dist/quill.snow.css';
import 'antd/dist/antd.variable.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { SWRConfig } from 'swr';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorMessage from './components/ErrorMessage';
import { ChatContextProvider } from './common/chatContext';

import './index_mobile.css';
import 'tributejs/tribute.css';
import { Provider } from 'react-redux';
import { setupStore } from './store/store';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const fetcher = (url: string) => axios.get(url).then(res => res.data);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
  enabled: !!process.env.REACT_APP_SENTRY_DSN,
});

export const primaryColor = '#4f67ff';

const config: any = {
  theme: {
    primaryColor,
  },
};
ConfigProvider.config(config);

const container = document.getElementById('root');
const root = createRoot(container!);

const store = setupStore();

root.render(
  <Sentry.ErrorBoundary fallback={ErrorMessage}>
    <ConfigCatProvider sdkKey={process.env.REACT_APP_CONFIG_CAT_SDKKEY!} pollingMode={PollingMode.AutoPoll}>
      <SWRConfig value={{ fetcher }}>
        <ChatContextProvider>
          <Router>
            <Provider store={store}>
              <App />
            </Provider>
          </Router>
        </ChatContextProvider>
      </SWRConfig>
    </ConfigCatProvider>
  </Sentry.ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
