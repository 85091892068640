import React from 'react';
import { Modal } from 'antd';
import { User } from '../../../entities';
import { UserChannel } from '../../../entities/chat';
import UserFinder from '../userFinder';

interface Props {
  user?: User;
  allUsers: User[];
  members: UserChannel[];
  channelId: string;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onAddUserToChannel;
  handleAddNewMembers;
}

const ChannelEditModal: React.FunctionComponent<Props> = ({
  user,
  allUsers,
  members,
  channelId,
  isModalOpen,
  setIsModalOpen,
  onAddUserToChannel,
  handleAddNewMembers,
}) => {
  const handleOk = () => {
    handleAddNewMembers();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal title="Add user" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <UserFinder
        onChange={onAddUserToChannel}
        options={allUsers
          ?.filter(user => !members?.some(mUser => mUser.user.id === user.id))
          .map(user => ({
            label: (
              <span key={user.id}>
                {/* <img className="my-[.2rem] w-5 h-5 rounded-full" src={user.picture} alt="" /> {user.name} */}
                {user.email}
              </span>
            ),
            value: `${user.name}/${user.id}`,
          }))}
      />
    </Modal>
  );
};

export default ChannelEditModal;
