import { useEffect, useState } from 'react';
import { Modal, Space } from 'antd';
import { Invoice } from '../../entities/Invoice';
import InvoiceForm from './InvoiceForm';
import { ProjectWithClientName } from './ProjectFinance';
import { formatInvoices, useInvoices } from '../../dal/useProjectInvoices';

type Props = {
  project: ProjectWithClientName;
  invoiceId: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const InvoiceModal = ({ project, invoiceId, onCancel, onSubmit }: Props) => {
  const { findInvoiceById, mutate, deleteInvoice, saveInvoice } = useInvoices(invoiceId);
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  useEffect(() => {
    findInvoiceById(project.id, invoiceId)
      .then(data => setInvoice(formatInvoices([data.data])[0]))
      .catch(console.error);
  }, [findInvoiceById, invoiceId, project.id]);

  const handleInvoiceDelete = async (invoiceId: string) => {
    await deleteInvoice(invoiceId);
    await mutate();
    onSubmit();
  };

  const handleInvoiceFormFinish = async (invoice: Invoice) => {
    await saveInvoice({ ...invoice, projectId: project.id });
    await mutate();
    onSubmit();
  };

  return (
    <Modal open={!!invoice} onCancel={onCancel} width="min(calc(100% - 40px), 1400px)" style={{ top: 20 }} destroyOnClose footer={null}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {invoice && (
          <InvoiceForm
            invoice={invoice}
            project={project}
            projectBillableRate={0}
            onSubmit={handleInvoiceFormFinish}
            onDelete={handleInvoiceDelete}
            onCancel={onCancel}
          />
        )}
      </Space>
    </Modal>
  );
};

export default InvoiceModal;
