import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChatUserChannelActivity } from '../../chat/entities/chat';

import { axiosBackend } from '../../api/backend';

interface ActivityState {
  activity: ChatUserChannelActivity | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ActivityState = {
  activity: null,
  isLoading: false,
  error: null,
};

export const fetchActivity = createAsyncThunk<ChatUserChannelActivity, { channelId: string }>(
  'activity/fetchActivity',
  async ({ channelId }, { rejectWithValue }) => {
    try {
      const response = await axiosBackend.get(`/chat/${channelId}/chatUserChannelActivity`);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to fetch chat user channel activity');
    }
  },
);

export const createActivity = createAsyncThunk<
  ChatUserChannelActivity,
  {
    channelId: string;
    lastReadMessageId: string;
  }
>('activity/createActivity', async (payload, { rejectWithValue }) => {
  const { channelId, lastReadMessageId } = payload;

  try {
    const response = await axiosBackend.post(`/chat/${channelId}/chatUserChannelActivity`, {
      lastReadMessageId,
    });

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to create message');
  }
});

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchActivity.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchActivity.fulfilled, (state, action) => {
        state.isLoading = false;
        const activity = action.payload;
        if (!activity) return;

        state.activity = activity;
      })

      .addCase(fetchActivity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Error loading chat user channel activity';
      })
      .addCase(createActivity.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        state.isLoading = false;
        const activity = action.payload;

        if (!activity) return;

        state.activity = activity;
      })
      .addCase(createActivity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to chat user channel activity';
      });
  },
});

export default activitySlice.reducer;
