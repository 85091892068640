export const AMOUNT_OF_LOADED_MESSAGES = 30;

export const CHANNEL_TYPES = Object.freeze({
  INDIVIDUAL: 'individual',
  PRIVATE: 'private',
  GLOBAL: 'global',
});

export const CHAT_UPLOAD_BASE_URL = 'https://ddc-app-dev.nyc3.digitaloceanspaces.com/uploads';
export const DEFAULT_AVATAR = 'https://via.placeholder.com/40';
