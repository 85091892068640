import { useEffect, useState } from 'react';
import { useTimesheets } from '../../dal';
import { Timesheet, TimesheetWeek, TimesheetMonth, User } from '../../entities';
import { getWeekDays, getLastSaturday, getFirstDayOfMonth } from '../../common/utils';
import { useSelfTimesheetForProject } from '../../dal/useSelfTimesheetForProject';
import { useUsersTimesheet } from '../../dal/useUsersTimesheet';
import moment from 'moment';
import { useUser } from '../../dal/useUser';

export function useTimesheetData({ projectId, userId, monthlyView }: { projectId?: string; userId?: number | string; monthlyView?: boolean } = {}): {
  startDate: string;
  activeDay: string;
  weekDays: string[];
  timesheets?: Timesheet;
  user: User;
  selfTimesheetForProject?: Timesheet;
  userTimesheet?: Timesheet;
  userTimesheetLoading?: boolean;
  weekSubmition: TimesheetWeek | null;
  monthSubmition: TimesheetMonth | null;
  weekSubmitionForProject: TimesheetWeek | null;
  getPreviousWeek: () => void;
  getNextWeek: () => void;
  getPreviousMonth: () => void;
  getNextMonth: () => void;
  refresh: () => void;
  setStartDate: (day: string) => void;
  setActiveDay: (day: string) => void;
} {
  const { user: overrideUser } = useUser(userId);
  const [startDate, setStartDate] = useState<string>(monthlyView ? getFirstDayOfMonth() : getLastSaturday());
  const [activeDay, setActiveDay] = useState<string>(startDate);
  const { timesheets, user, pagination, weekSubmition, monthSubmition, mutate } = useTimesheets(startDate, monthlyView);
  const {
    timesheets: selfTimesheetForProject,
    mutate: mutateSelfTimesheetForProject,
    weekSubmition: weekSubmitionForProject,
  } = useSelfTimesheetForProject(projectId === 'all' ? undefined : projectId, startDate);
  const {
    timesheets: userTimesheet,
    isLoading: userTimesheetLoading,
    mutate: mutateUserTimesheet,
  } = useUsersTimesheet({ userId, startDate, projectId, monthlyView });

  const getPreviousWeek = () => {
    setStartDate(pagination?.prev);
  };
  const getNextWeek = () => {
    setStartDate(pagination?.next);
  };

  const getPreviousMonth = () => {
    setStartDate(pagination?.prev);
  };
  const getNextMonth = () => {
    setStartDate(pagination?.next);
  };

  const refresh = async () => {
    await mutate(null, true);
    await mutateSelfTimesheetForProject(null, true);
    await mutateUserTimesheet(null, true);
  };

  useEffect(() => {
    if (!startDate) return;

    const dayDiff = moment.duration(moment().diff(moment(startDate))).asDays();
    if (dayDiff >= 0 && dayDiff < 7) {
      setActiveDay(moment().format('MMM D, YYYY'));
    } else {
      setActiveDay(moment(startDate).format('MMM D, YYYY'));
    }
  }, [startDate]);

  return {
    timesheets,
    user: userId ? overrideUser : user,
    selfTimesheetForProject,
    userTimesheet,
    userTimesheetLoading,
    getPreviousWeek,
    getNextWeek,
    getPreviousMonth,
    getNextMonth,
    weekSubmition,
    monthSubmition,
    weekSubmitionForProject,
    weekDays: getWeekDays(startDate),
    startDate,
    refresh,
    activeDay,
    setStartDate,
    setActiveDay,
  };
}
