import useSWR from 'swr';
import { FetchResult, ProjectMember } from '../entities';

type Result = FetchResult & { members: ProjectMember[] };

export function useProjectMembers(id: string): Result {
  const { data, error, mutate } = useSWR(`/projects/${id}/members`);

  return {
    members: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
