import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatMessageReplyProps } from '../../chat/entities/chat';

interface ReplyToState {
  replyTo: ChatMessageReplyProps;
}

const initialState: ReplyToState = {
  replyTo: {
    id: '',
    text: '',
  },
};

const replyToSlice = createSlice({
  name: 'replyTo',
  initialState,
  reducers: {
    setReplyTo: (state, action: PayloadAction<ChatMessageReplyProps>) => {
      state.replyTo = action.payload;
    },
  },
});

export const { setReplyTo } = replyToSlice.actions;

export default replyToSlice.reducer;
