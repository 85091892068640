import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PusherEvent, receiveEvent } from './pusherEventsSlice';

interface Notification {
  id: string;
  message: string;
  eventName: string;
  timestamp: string;
}

interface NotificationsState {
  notifications: Notification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(receiveEvent, (state, action: PayloadAction<PusherEvent>) => {
      const { eventName, data } = action.payload;

      let message = '';
      const timestamp = new Date().toISOString();

      switch (eventName) {
        case 'chat:new-channel':
          message = `New channel created: ${data.name} (${data.type})`;
          break;

        case 'user:added-to-channel':
          message = `You added to the new channel: ${data.name} (${data.type})`;
          break;

        case 'chat:new-member':
          message = `User ${data.user.user.user.email} added to channel: ${data.user.channel.name}`;
          break;

        case 'chat:new-message':
          message = `New messages from ${data.message.author.id} in ${data.channelId}: "${data.message.text}"`;
          break;

        case 'chat:new-reaction':
          message = `New reaction from ${data.userName} in ${data.channelId}: "${data.emoji}"`;
          break;

        default:
          message = `New event: ${eventName}`;
      }

      const isRemoveReaction = eventName === 'chat:new-reaction' && !data.emoji;
      const isMessageUpdateOrDeleteEvent = ['chat:message-deleted', 'chat:message-updated'].includes(eventName);

      if (isRemoveReaction || isMessageUpdateOrDeleteEvent) {
        return;
      }

      state.notifications.push({
        id: data.id || crypto.randomUUID(),
        message,
        eventName,
        timestamp,
      });
    });
  },
});

export default notificationsSlice.reducer;
