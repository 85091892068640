import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PusherEvent {
  eventName: string;
  data: any;
}

interface PusherState {
  events: PusherEvent[];
  lastEvent: PusherEvent | null;
}

const initialState: PusherState = {
  events: [],
  lastEvent: null,
};

export const receiveEvent = createAction<PusherEvent>('pusherEvents/receiveEvent');

const pusherEventsSlice = createSlice({
  name: 'pusherEvents',
  initialState,
  reducers: {
    clearEvents: state => {
      state.events = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(receiveEvent, (state, action: PayloadAction<PusherEvent>) => {
      state.events.push(action.payload);
      state.lastEvent = action.payload;

      if (state.events.length > 50) {
        state.events.shift();
      }
    });
  },
});

export const { clearEvents } = pusherEventsSlice.actions;
export default pusherEventsSlice.reducer;
