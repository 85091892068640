import { useCallback, useEffect, useState } from 'react';
import Layout from '../../layouts/authorised/Authorised';
import { pusher } from '../../hooks/usePusherInit';
import { Badge, Col, List, PageHeader, Row, Tabs, Tag, Timeline, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useImmerReducer } from 'use-immer';

function reducer(draft, action) {
  console.log(action.type, action);
  const data = action.data;
  switch (action.type) {
    case 'added-to-channel':
    case 'new-channel':
      draft[data.id] = { data };
      break;
    case 'new-message':
      draft[data.channel.id].messages = [...(draft[data.channel.id].messages || []), data];
      break;
    case 'new-member':
      draft[data.channel.id].data.members = [...(draft[data.channel.id].data.members || []), data.user];
      break;
  }
}

const ChatDebugConsole = () => {
  const [state, dispatch] = useImmerReducer<any, any>(reducer, {});
  const [logMessages, setLogMessages] = useState<any>([]);

  const log = useCallback(
    (message, data) => {
      setLogMessages(prevMessages => [
        ...prevMessages,
        {
          label: moment().toISOString(),
          message,
          data,
        },
      ]);
    },
    [setLogMessages],
  );

  const newMessageCallback = useCallback(
    (data: any) => {
      console.log('data', data);
      log(`New message in channel ${data.channel.id} received`, data);
      dispatch({
        type: 'new-message',
        data,
      });
    },
    [log, dispatch],
  );

  const newMemberCallback = useCallback(
    (data: any) => {
      log(`New member in channel ${data.channel.name}`, data);
      dispatch({
        type: 'new-member',
        data,
      });
    },
    [log, dispatch],
  );

  const newChannelCallback = useCallback(
    (data: any) => {
      const channelId = `private-${data.id}`;
      log(`New channel ${channelId} created`, data);
      dispatch({
        type: 'new-channel',
        data,
      });

      const channel = pusher.subscribe(channelId);
      channel.bind_global(data => console.log('bind_global', data));
      channel.bind('chat:new-message', newMessageCallback);
      channel.bind('chat:new-member', newMemberCallback);
    },
    [dispatch, log, newMemberCallback, newMessageCallback],
  );

  const userAddedToChannelCallback = useCallback(
    (data: any) => {
      console.log('user:added-to-channel', data);
      log(`You were added to a channel ${data.name}`, data);
      dispatch({
        type: 'added-to-channel',
        data,
      });

      const channelId = `private-${data.id}`;
      const channel = pusher.subscribe(channelId);
      channel.bind('chat:new-message', newMessageCallback);
      channel.bind('chat:new-member', newMemberCallback);
    },
    [log, dispatch, newMessageCallback, newMemberCallback],
  );

  useEffect(() => {
    log('Signing in to pusher: ' + pusher.connection.state, null);
    pusher.signin();
    log('Connected: ' + pusher.connection.state, null);

    const systemMessages = pusher.subscribe('private-system-messages');
    systemMessages.bind('chat:new-channel', newChannelCallback);

    pusher.user.bind('user:added-to-channel', userAddedToChannelCallback);
  }, [log, newChannelCallback, userAddedToChannelCallback]);

  return (
    <Layout isFullPage fullHeight>
      <PageHeader title="Chat Debug Console" />
      <Row gutter={8}>
        <Col span={6}>
          <Timeline mode="left" reverse>
            {logMessages.map(m => (
              <Timeline.Item key={m.label} label={m.label}>
                <Tooltip title={JSON.stringify(m.data, null, 2)}>{m.message}</Tooltip>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
        <Col span={12} style={{ backgroundColor: 'lightgray' }}>
          <Tabs tabPosition="left">
            {Object.keys(state).map(channelId => (
              <Tabs.TabPane
                tab={
                  <>
                    <Badge count={state[channelId].messages?.length} size="small">
                      <Tag>{state[channelId].data.type.substring(0, 2).toUpperCase()}</Tag>
                      {state[channelId].data.name}
                    </Badge>
                    <br />
                    <Typography.Text type="secondary">{channelId}</Typography.Text>
                  </>
                }
                key={channelId}
              >
                <Typography.Title level={5}>Members</Typography.Title>
                {(state[channelId].data.members || []).map(m => (
                  <Tag key={m.user.id}>{m.user.email}</Tag>
                ))}
                <Typography.Title level={5}>Messages</Typography.Title>
                <List
                  bordered
                  dataSource={state[channelId]?.messages || []}
                  renderItem={(message: any) => (
                    <List.Item key={message.id}>
                      <Typography.Text mark>[{moment(message.createdAt).toISOString()}]</Typography.Text> {message.text}
                    </List.Item>
                  )}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
        <Col span={6}>
          <pre>{JSON.stringify(state, null, 2)}</pre>
        </Col>
      </Row>
    </Layout>
  );
};

export default ChatDebugConsole;
