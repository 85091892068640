import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Spin, message, Table, Space, Form, Statistic, Typography } from 'antd';
import moment from 'moment';
import { FilePdfOutlined, FileAddOutlined, PercentageOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFInvoice from './PDFInovice';
import { InvoiceStatusTag } from './InvoiceStatusTag';
import UsersCommissionModal from './ProjectFinance.commissionModal';
import { useProjectFinance } from '../../dal';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { Client, Project, ProjectFinance } from '../../entities';
import { formatCurrency, formatDate, formatDateTime } from '../../common/utils';
import { useProjectInvoices } from '../../dal/useProjectInvoices';
import { getInvoiceRowCssClass, Invoice } from '../../entities/Invoice';
import MoneyInput from '../shared/MoneyInput';

import './ProjectFinance.css';
import { getStatisticsProfitColor } from '../../pages/reports/budget';

export type ProjectWithClientName = Pick<Project, 'id' | 'name' | 'autoCode'> & {
  client: Client extends null ? null : Pick<Client, 'name'> | null;
};

const ProjectFinances = ({ project }: { project: ProjectWithClientName }) => {
  const { id: projectId, autoCode: projectCode, name: projectName, client } = project;

  const navigate = useNavigate();
  const { finance, mutate: mutateFinance, saveFinance } = useProjectFinance(projectId);
  const { invoices, totalValue, totalActualCostWithCommission, totalActualCostWithoutCommission, totalMarkupPercentage } =
    useProjectInvoices(projectId);
  const { ability } = usePermissions();
  const [openCommission, setOpenCommission] = useState<boolean>(false);

  const onFinish = useCallback(
    async (values: ProjectFinance) => {
      await saveFinance({ billableRate: values.billableRate });
      await mutateFinance();
      message.success('Billable rate saved');
    },
    [saveFinance, mutateFinance],
  );

  const activeInvoicesAmount = useMemo(() => invoices?.filter(invoice => invoice.isActive).length, [invoices]);

  if (!invoices || !finance) return <Spin />;

  if (ability.can('view', 'Invoice')) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify="space-between">
          <Space>
            <Form layout="inline" initialValues={finance} onFinish={onFinish}>
              <Form.Item label="Billable rate" name="billableRate">
                <MoneyInput style={{ width: '75px' }} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Save</Button>
              </Form.Item>
            </Form>
          </Space>

          <Space>
            <Statistic
              title={`Internal spend (${invoices.length})`}
              value={totalActualCostWithCommission}
              prefix="$"
              precision={2}
              style={{ margin: '0 5px' }}
            />
            <Statistic
              title={
                <>
                  Internal spend <Typography.Text type="danger">(ex com)</Typography.Text> ({invoices.length})
                </>
              }
              value={totalActualCostWithoutCommission}
              prefix="$"
              precision={2}
              style={{ margin: '0 5px' }}
            />
            <Statistic title={`Billed (${activeInvoicesAmount})`} value={totalValue} prefix="$" precision={2} style={{ margin: '0 5px' }} />
            <Statistic
              title={
                <>
                  All time profit percentage <Typography.Text type="danger">(ex com)</Typography.Text> ({activeInvoicesAmount})
                </>
              }
              value={totalMarkupPercentage || 0}
              suffix="%"
              precision={2}
              style={{
                margin: '0 5px',
              }}
              valueStyle={{
                color: getStatisticsProfitColor(totalMarkupPercentage || 0),
              }}
            />
          </Space>

          <Space>
            {ability.can('update', 'Commission') && (
              <Button icon={<PercentageOutlined />} onClick={() => setOpenCommission(true)}>
                Add commission
              </Button>
            )}

            <Button icon={<FileAddOutlined />} onClick={() => navigate(`/projects/${projectId}/invoices/newInvoice`)}>
              Create invoice
            </Button>
          </Space>
        </Row>

        <Table dataSource={invoices} rowClassName={getInvoiceRowCssClass} size="small" pagination={false} className="non-chat-table" rowKey="id">
          <Table.Column
            dataIndex="autoCode"
            title="Invoice #"
            render={(_, invoice: Invoice) => (
              <Button type="link" onClick={() => navigate(`/projects/${projectId}/invoices/${invoice.id}`)} style={{ padding: 0 }}>
                {invoice.autoCode}
              </Button>
            )}
          />
          <Table.Column dataIndex="invoiceNumber" title="External #" />
          <Table.Column dataIndex="value" title="Value" render={(value: number) => formatCurrency(value)} />
          <Table.Column dataIndex="markupPercentage" title="Markup percentage" render={(value: number) => `${value || 0}%`} />
          <Table.Column dataIndex="forMonth" title="For month" render={value => (value ? moment(value).format('MMMM YYYY') : null)} />
          <Table.Column dataIndex="due" title="Bill for" />
          <Table.Column dataIndex="estimatedPayDate" title="Estimated pay date" render={(estimatedPayDate: string) => formatDate(estimatedPayDate)} />
          <Table.Column dataIndex="sentDate" title="Sent date" render={(sentDate: string) => formatDate(sentDate)} />
          <Table.Column dataIndex="paidDate" title="Paid date" render={(paidDate: string) => formatDate(paidDate)} />
          <Table.Column dataIndex="createdAt" title="Created at" render={(createdAt: string) => formatDateTime(createdAt)} />
          <Table.Column dataIndex="status" title="Status" render={(_, invoice: Invoice) => <InvoiceStatusTag invoice={invoice} />} />
          <Table.Column dataIndex="isActive" title="Is active?" render={(_, invoice: Invoice) => (invoice.isActive ? 'Active' : 'Draft')} />
          <Table.Column
            dataIndex="id"
            render={(_, invoice: Invoice) => (
              <PDFDownloadLink
                document={<PDFInvoice invoice={invoice} project={{ projectName, projectCode }} />}
                fileName={`${invoice.autoCode}-${client?.name}-${projectName}-${projectCode}.pdf`}
              >
                <Button target="_blank" block icon={<FilePdfOutlined />} type="link">
                  Download
                </Button>
              </PDFDownloadLink>
            )}
          />
        </Table>

        {openCommission && ability.can('update', 'Commission') && (
          <UsersCommissionModal projectId={projectId} onCancel={() => setOpenCommission(false)} />
        )}
      </Space>
    );
  }

  return null;
};

export default ProjectFinances;
