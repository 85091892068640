import { Popover, Button, Popconfirm } from 'antd';
import { SmileOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import EmojiPicker from 'emoji-picker-react';

interface MessageMenuPopupProps {
  isMyMessage: boolean;
  onReaction: (
    emojiData:
      | {
          emoji: string;
        }
      | string,
  ) => void;
  onDelete: () => void;
  onUpdate: () => void;
}

const MessageMenuPopup = ({ isMyMessage, onReaction, onDelete, onUpdate }: MessageMenuPopupProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 40,
        right: 50,
        background: 'white',
        padding: '8px',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        gap: '8px',
      }}
    >
      <Popover content={<EmojiPicker onEmojiClick={onReaction} />} trigger="click">
        <Button shape="circle" icon={<SmileOutlined />} size="small" />
      </Popover>
      {isMyMessage && <Button shape="circle" icon={<EditOutlined />} size="small" onClick={onUpdate} />}
      {isMyMessage && (
        <Popconfirm title="Are you sure?" onConfirm={onDelete}>
          <Button shape="circle" icon={<DeleteOutlined />} size="small" danger />
        </Popconfirm>
      )}
    </div>
  );
};

export default MessageMenuPopup;
