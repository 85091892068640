import { useEffect, useMemo } from 'react';
import { useAppDispatch } from '../hooks/redux';
import { receiveEvent } from '../store/reducers/pusherEventsSlice';
import Pusher from 'pusher-js';
import { useSession } from '../dal';
import { useFeatureFlag } from 'configcat-react';

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || '',
  channelAuthorization: {
    endpoint: '/chat/auth',
    transport: 'ajax',
  },
  userAuthentication: {
    endpoint: '/chat/auth-user',
    transport: 'ajax',
  },
});

export const usePusherInit = () => {
  const { session } = useSession();
  const { value: chatEnabled } = useFeatureFlag('chat', false, { identifier: session?.email! });

  const dispatch = useAppDispatch();
  const systemChannel = useMemo(
    () => ({
      name: `chat-base-events-${process.env.REACT_APP_CHAT_ID}`,
      global: true,
    }),
    [],
  );

  useEffect(() => {
    if (!session || !chatEnabled) {
      return;
    }

    console.log('Initialize Pusher...');

    pusher.signin();

    const channel = pusher.subscribe(systemChannel.name);

    if (systemChannel.global) {
      channel.bind_global((eventName: string, data: any) => {
        dispatch(receiveEvent({ eventName, data }));
      });
    }

    return () => {
      console.log('Unsubscribe from Pusher...');
      pusher.unsubscribe(systemChannel.name);
      pusher.disconnect();
    };
  }, [dispatch, chatEnabled, session, systemChannel]);
};
