import React, { useEffect } from 'react';

import { Divider, PageHeader } from 'antd';

import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import ChannelMembersModal from '../channelMembersModal';
import UserFinder from '../userFinder';
import Editor from '../Editor';
import { User } from '../../../entities';
import { ChatChannel, UserChannel } from '../../../entities/chat';
import Messages from '../Messages';
import { isMemeberOfChat } from '../../../common/utils';
import ChannelEditModal from '../channelEditModal';
import { useLocation, useParams } from 'react-router-dom';
import { useChatApi } from '../../../../api/useChatApi';
import { useAppSelector } from '../../../../hooks/redux';
import { RootState } from '../../../../store/store';
import { useSession, useUsersBrief } from '../../../../dal';

interface Props {
  handleChannelListOpen;
  renameChannel: (name: string) => Promise<void>;
  channel: ChatChannel | any;
  isCreatingNewChannel: boolean;
  newChannelMembers: string[];
  onAddUserToChannel: React.Dispatch<React.SetStateAction<string[]>>;
  isAbleToRemoveUser?: boolean;
  type: string;
  userWithWhoChat?: UserChannel;
  createNewChannel?: (values: { name: string; members: User[] }) => void;
}

const ChatArea: React.FunctionComponent<Props> = ({
  handleChannelListOpen,
  renameChannel,
  channel,
  isCreatingNewChannel,
  onAddUserToChannel,
  newChannelMembers,
  isAbleToRemoveUser,
  type,
  userWithWhoChat,
  createNewChannel,
}) => {
  const [isMembersModalOpen, setIsMembersModalOpen] = React.useState(false);
  const [isChannelEditModalOpen, setIsChannelEditModalOpen] = React.useState(false);
  const location = useLocation();
  const { addMembers } = useChatApi();
  const { id: channelId } = useParams();

  function handleMembersClick() {
    setIsMembersModalOpen(true);
  }

  function handleChannelEditClick() {
    setIsChannelEditModalOpen(true);
  }

  const [isSameChannelExist, setIsSameChannelExist] = React.useState<ChatChannel | undefined>();

  useEffect(() => {
    setIsSameChannelExist(undefined);
    onAddUserToChannel([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { users } = useUsersBrief({ activeOnly: true });
  const { channels } = useAppSelector((state: RootState) => state.channelReducer);

  const { session } = useSession();

  function handleAddNewMembers() {
    if (channel) {
      if (channel.isNameEdited) {
        addMembers(
          channel.id,
          newChannelMembers.map(user => user.split('/')[1]),
        );
      } else {
        const channelMembers = channel.members.reduce(
          (pV, cV) => (cV.user.id !== session?.id ? [...pV, cV.user.name + '/' + cV.user.id] : pV),
          newChannelMembers,
        );

        if (createNewChannel) {
          const membersFormated: User[] = channelMembers.map(member => users.find(user => user.id === member.split('/')[1]));
          createNewChannel({ name: '', members: membersFormated });
        }
      }
    }
  }

  useEffect(() => {
    if (newChannelMembers && newChannelMembers.length) {
      const result = channels.find(channel => {
        return !channel.isNameEdited && channel.members?.length === newChannelMembers?.length + 1 && isMemeberOfChat(channel, newChannelMembers);
      });
      // if (result && isCreatingNewChannel) {
      //   retrieveMessages(result.id);
      // }
      setIsSameChannelExist(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChannelMembers, channels]);

  const NameChangeRef = React.createRef<HTMLDivElement>();
  const [isInEditMode, setIsInEditMode] = React.useState(false);

  return (
    <div className="bg-white rounded-[8px] h-full">
      <ChannelEditModal
        handleAddNewMembers={handleAddNewMembers}
        onAddUserToChannel={onAddUserToChannel}
        allUsers={users}
        members={channel?.users}
        channelId={channel?.id}
        setIsModalOpen={setIsChannelEditModalOpen}
        isModalOpen={isChannelEditModalOpen}
      />
      <ChannelMembersModal
        isAbleToRemove={isAbleToRemoveUser || false}
        allUsers={users}
        members={channel?.members}
        channelId={channel?.id}
        setIsModalOpen={setIsMembersModalOpen}
        isModalOpen={isMembersModalOpen}
      />
      <PageHeader
        className="chat-header rounded-t-[8px]"
        title={
          <div className="flex items-center">
            <ArrowLeftOutlined className="block md:hidden text-gray-600 ml-2 mr-3 font-bold" onClick={handleChannelListOpen} />
            {isCreatingNewChannel ? (
              <>
                <h3 className="overflow-hidden text-ellipsis m-0 mr-3 font-semibold ">New direct message to: </h3>{' '}
                <UserFinder
                  onChange={onAddUserToChannel}
                  options={users
                    // TODO: uncomment
                    // .filter(user => user.id !== session?.id)
                    .map(user => ({
                      // label: (
                      //   <span key={user.id}>
                      //     <img className="my-[.2rem] w-5 h-5 rounded-full" src={user.picture} alt="" /> {user.name}
                      //   </span>
                      // ),
                      label: user.email,
                      value: `${user.email}/${user.id}`,
                    }))}
                />
              </>
            ) : (
              <>
                <div
                  tabIndex={1}
                  ref={NameChangeRef}
                  className={` overflow-hidden text-black font-semibold text-lg p-1 px-2 outline-none rounded-lg ${
                    isInEditMode ? ' border-solid !border !border-blue-600' : 'text-ellipsis'
                  }`}
                  suppressContentEditableWarning
                  contentEditable={channel.isNameEdited || channel.members?.length > 2}
                  onFocus={e => {
                    if (channel.isNameEdited || channel.members?.length > 2) {
                      const range = document.createRange();
                      range.selectNodeContents(e.target);
                      const sel = window.getSelection();
                      sel?.removeAllRanges();
                      sel?.addRange(range);
                      setIsInEditMode(true);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (NameChangeRef.current?.innerText) {
                        renameChannel(NameChangeRef.current?.innerText);
                        NameChangeRef.current?.blur();
                      }
                    }
                  }}
                  onBlur={data => {
                    if (data.relatedTarget?.id !== 'accept_rename' || !data.target.innerText || data.target.innerText.length < 1) {
                      data.target.innerText = userWithWhoChat?.user.name
                        ? userWithWhoChat?.user.name
                        : channel.members
                        ? channel.isNameEdited
                          ? channel.name
                          : channel.members
                              .filter(member => member.user.id !== session?.id)
                              .map(member => member.user.email)
                              .reduce(
                                (pV, cV, i, arr) =>
                                  i < 2
                                    ? `${pV ? pV + ', ' : ''}${cV}`
                                    : i === 2
                                    ? `${pV}, ${arr.length - 2} other${arr.length - 2 > 1 ? 's' : ''}.`
                                    : pV,
                                '',
                              )
                        : channel.name;
                    } else if (data.target.innerText) {
                      renameChannel(data.target.innerText);
                    }
                    setIsInEditMode(false);
                  }}
                >
                  {userWithWhoChat?.user.name
                    ? userWithWhoChat?.user.name
                    : channel.members
                    ? channel.isNameEdited
                      ? channel.name
                      : channel.members
                          .filter(member => member.user.id !== session?.id)
                          .map(member => member.user.email)
                          .reduce(
                            (pV, cV, i, arr) =>
                              i < 2
                                ? `${pV ? pV + ', ' : ''}${cV}`
                                : i === 2
                                ? `${pV}, ${arr.length - 2} other${arr.length - 2 > 1 ? 's' : ''}.`
                                : pV,
                            '',
                          )
                    : channel.name}
                </div>
                {channel.isNameEdited || channel.members?.length > 2 ? (
                  isInEditMode ? (
                    <>
                      <CheckOutlined id="accept_rename" onClick={() => {}} className="ml-1 hover:text-blue-600" />
                      <CloseOutlined
                        id="decline_rename"
                        onClick={() => {
                          if (NameChangeRef.current) {
                            NameChangeRef.current.innerText = userWithWhoChat?.user.name
                              ? userWithWhoChat?.user.name
                              : channel.members
                              ? channel.isNameEdited
                                ? channel.name
                                : channel.members.reduce((pV, cV) => (cV.user.id !== session?.id ? `${pV ? pV + ',' : ''} ${cV.user.name}` : pV), '')
                              : channel.name;
                          }
                        }}
                        className="ml-1 hover:text-blue-600"
                      />
                    </>
                  ) : channel.isNameEdited ? (
                    <EditOutlined
                      className=" hover:text-blue-600 ml-1"
                      onClick={() => {
                        NameChangeRef.current?.focus();
                      }}
                    />
                  ) : (
                    <div
                      className=" text-sm transition-all h-8 w-fit rounded-[6px] backshadow cursor-pointer hover:bg-[#4F67FF] hover:text-white select-none flex justify-center items-center px-2 mr-1"
                      onClick={() => {
                        if (NameChangeRef.current) {
                          NameChangeRef.current.innerText = '';
                          NameChangeRef.current.focus();
                        }
                      }}
                    >
                      Convert to channel
                    </div>
                  )
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        }
        extra={[
          type !== 'nothing' && (
            <div key="no-nothing" className="flex h-9 w-fit rounded-[6px] backshadow select-none overflow-hidden">
              <div
                onClick={handleMembersClick}
                className="transition-all hover:bg-[#4F67FF] two-user w-[57px] h-full flex items-center justify-center cursor-pointer"
              >
                <div className="two-user-icon w-[20px]"></div>
                <div className="ml-2">{channel?.users?.length}</div>
              </div>
              <Divider type="vertical" className="!m-0 !h-full" />
              <div
                onClick={channel.type !== 'individual' ? handleChannelEditClick : undefined}
                className={`transition-all hover:bg-[#4F67FF] add-user w-[36px] h-full flex items-center justify-center cursor-pointer ${
                  channel.type === 'individual' && 'hover:bg-[#fc3725] !cursor-not-allowed'
                }`}
              >
                <div className="add-user-icon w-[20px]"></div>
              </div>
            </div>
          ),
        ]}
      />
      {isSameChannelExist && isCreatingNewChannel && newChannelMembers?.length > 0
        ? session && <Messages loggedInUserId={session?.id} users={users} channelId={isSameChannelExist?.id} />
        : session && <Messages loggedInUserId={session?.id} users={users} channelId={channelId || ''} />}

      {location && !(isCreatingNewChannel && newChannelMembers?.length === 0) && <Editor users={users} channelId={channel?.id} />}
    </div>
  );
};

export default ChatArea;
