import moment from 'moment';
import { Moment } from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import { Project, ProjectsReportItem } from '.';

export type RangeValueType = RangePickerProps['value'];

export interface BillingAddress {
  address: string;
  apartment: string;
  zip: string;
  phone: string;
}

interface BillingDetails {
  name: string;
  billingAddress: BillingAddress;
}

export interface Invoice {
  id?: string;
  ordinal: number;
  autoCode: string;
  invoiceNumber: string;
  value: number;
  actualCost: number;
  paid: boolean;
  description?: string;
  clientNote?: string;
  invoiceLink: string;
  clientInvoiceLink: string;
  due?: string;
  estimatedPayDate?: Moment | Date | null;
  sentDate?: Moment | Date | null;
  paidDate?: Moment | Date | null;
  forMonth?: Moment | Date | null;
  isActive?: boolean;
  groupByPhase: boolean;
  dateRange: RangeValueType;
  details: InvoiceLineItem[];
  markupPercentage: number;
  sellerDetails?: BillingDetails;
  buyerDetails?: BillingDetails;
  project?: Project;
  projectId?: string;
  commissionAmount?: number;
  isApproved?: boolean;
  approveDate?: Date;
  approvedTimesheetCost?: number;
}

export interface InvoiceLineItem {
  key: string;
  billableHours: number;
  billableTotal: number;
  billableRate: number;
  fixedBillableTotal: number;
  totalCost?: number;
  fixedCost: number;
  totalMinutes?: number | string;
  phaseName: string;
  category: string;
  isCommission?: boolean;
  lineItemName?: string;
  type?: string;
  isLineItem?: boolean;
  newTotalCost?: number;
  newTotalMinutes?: number;
  // isNew?: boolean;
}

export const getInvoiceRowCssClass = (invoice: Invoice | ProjectsReportItem): string => {
  if (!invoice.isActive) return 'draft-invoice';

  if (invoice.paidDate) return 'paid-invoice';

  if (moment(invoice.estimatedPayDate).isBefore(moment())) return 'overdue-invoice';

  if (invoice.sentDate) return 'sent-invoice';

  return 'active-invoice';
};
