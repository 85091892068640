import { useState } from 'react';
import { Button, PageHeader, Popconfirm, Space, Tag, Switch } from 'antd';
import { ClockCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Layout from '../../layouts/authorised/Authorised';
import Title from 'antd/lib/typography/Title';
import TimesheetForm from '../../components/Timesheet/TimesheetForm';
import { useTimesheetData } from './useTimesheetData';
import { useTimesheetModal } from '../../components/Timesheet/useTimesheetModal';
import {
  formatDateTime,
  getApprovalStatus,
  getLastSaturday,
  getFirstDayOfMonth,
  isLastDayOfMonth,
  displayHoursFromNumber,
  getTotalHours,
  getTotalCost,
  formatCurrency,
} from '../../common/utils';
import TimesheetTabs from '../../components/Timesheet/TimesheetTabs';
import TimesheetMonth from '../../components/Timesheet/TimesheetMonth';
import TimesheetTabsControls from '../../components/Timesheet/TimesheetTabsControls';
import axios from 'axios';
import { ApprovalStatus } from '../../entities';
import moment from 'moment';
import { ReactComponent as IconCheckRound } from '../../images/icons/icon-check-round.svg';

const Timesheet = () => {
  const [monthlyView, setMontlyView] = useState(false);
  const {
    timesheets,
    user,
    getPreviousWeek,
    getNextWeek,
    getPreviousMonth,
    getNextMonth,
    activeDay,
    setStartDate,
    setActiveDay,
    weekSubmition,
    monthSubmition,
    weekDays,
    refresh,
    startDate,
  } = useTimesheetData({ monthlyView });
  const { entryId, modalOpen, openModal, setModalOpen, setEntryId } = useTimesheetModal();

  const dayChange = (date: string) => {
    setActiveDay(date);
  };

  const monthlyViewChange = (checked: boolean) => {
    setMontlyView(checked);
    setStartDate(checked ? getFirstDayOfMonth() : getLastSaturday());
    setActiveDay(moment(checked ? getFirstDayOfMonth() : new Date()).format('MMM D, YYYY'));
  };

  const submitWeek = async () => {
    await axios.post(`/timesheets/submit/week`, { year: new Date(startDate).getFullYear(), week: startDate });
    await refresh();
  };

  const submitMonth = async () => {
    const d = new Date(startDate);
    await axios.post(`/timesheets/submit/month`, { year: d.getFullYear(), month: d.getMonth() });
    await refresh();
  };

  return (
    <Layout overrideBackgroundContainer>
      <div className="custom-container">
        <PageHeader
          className="ant-page-header--custom"
          title="Timesheet"
          tags={
            <Space>
              {monthlyView ? (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />
                  </div>
                  <Title level={5} style={{ marginBottom: 0 }}>
                    {formatDateTime(activeDay, 'MMMM, YYYY')}
                  </Title>
                </>
              ) : (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <TimesheetTabsControls onGetPrevious={getPreviousWeek} onGetNext={getNextWeek} />
                  </div>
                  <Title level={5} style={{ marginBottom: 0 }}>
                    {formatDateTime(weekDays[0], 'D MMM')} - {formatDateTime(weekDays[6], 'D MMM')}
                  </Title>
                </>
              )}
              <Switch checkedChildren="Monthly" unCheckedChildren="Monthly" onChange={checked => monthlyViewChange(checked)} />
            </Space>
          }
          extra={
            <>
              {!monthlyView && (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: 8 }}>Weekly total:</span>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <strong>{displayHoursFromNumber(getTotalHours(timesheets))}</strong>
                      <strong>{formatCurrency(getTotalCost(timesheets))}</strong>
                    </div>
                  </div>

                  {weekDays.find(d => isLastDayOfMonth(new Date(d))) && (
                    <>
                      {getApprovalStatus(monthSubmition) === ApprovalStatus.APPROVED && (
                        <Tag icon={<CheckCircleOutlined />} color="success">
                          Month Timesheet approved
                        </Tag>
                      )}
                      {getApprovalStatus(monthSubmition) === ApprovalStatus.REJECTED && (
                        <Tag icon={<ExclamationCircleOutlined />} color="warning">
                          Month Timesheet rejected: {monthSubmition?.comment}
                        </Tag>
                      )}
                      {(getApprovalStatus(monthSubmition) === ApprovalStatus.AWAITING_APPROVAL ||
                        getApprovalStatus(monthSubmition) === ApprovalStatus.AWAITING_REAPPROVAL) && (
                        <Tag icon={<ClockCircleOutlined />} color="processing">
                          Month awaiting approval
                        </Tag>
                      )}
                      {(getApprovalStatus(monthSubmition) === ApprovalStatus.READY_TO_SUBMIT ||
                        getApprovalStatus(monthSubmition) === ApprovalStatus.REJECTED) && (
                        <Popconfirm title="Do you want to submit the ending month?" onConfirm={submitMonth}>
                          <Button type="primary" icon={<IconCheckRound />} className="ant-btn--custom-icon">
                            Submit month
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  )}
                  {getApprovalStatus(weekSubmition) === ApprovalStatus.APPROVED && (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      Week Timesheet approved
                    </Tag>
                  )}
                  {getApprovalStatus(weekSubmition) === ApprovalStatus.REJECTED && (
                    <Tag icon={<ExclamationCircleOutlined />} color="warning">
                      Week Timesheet rejected: {weekSubmition?.comment}
                    </Tag>
                  )}
                  {(getApprovalStatus(weekSubmition) === ApprovalStatus.AWAITING_APPROVAL ||
                    getApprovalStatus(weekSubmition) === ApprovalStatus.AWAITING_REAPPROVAL) && (
                    <Tag icon={<ClockCircleOutlined />} color="processing">
                      Week awaiting approval
                    </Tag>
                  )}
                  {(getApprovalStatus(weekSubmition) === ApprovalStatus.READY_TO_SUBMIT ||
                    getApprovalStatus(weekSubmition) === ApprovalStatus.REJECTED) && (
                    <Popconfirm title="Do you want to submit the current week?" onConfirm={submitWeek}>
                      <Button type="primary" icon={<IconCheckRound />} className="ant-btn--custom-icon">
                        Submit week
                      </Button>
                    </Popconfirm>
                  )}
                </>
              )}
            </>
          }
        />

        {timesheets &&
          (monthlyView ? (
            <TimesheetMonth timesheets={timesheets} rates={user} activeDay={activeDay} />
          ) : (
            <TimesheetTabs
              displayFinancialData={true}
              refreshTimesheets={refresh}
              addEntryEnabled={true}
              activeDay={activeDay}
              timesheets={timesheets}
              user={user}
              days={weekDays}
              onEditClick={
                getApprovalStatus(weekSubmition) === ApprovalStatus.AWAITING_APPROVAL ||
                getApprovalStatus(weekSubmition) === ApprovalStatus.AWAITING_REAPPROVAL ||
                getApprovalStatus(weekSubmition) === ApprovalStatus.APPROVED
                  ? undefined
                  : openModal
              }
              onDayChange={dayChange}
            />
          ))}
        {modalOpen && entryId && (
          <TimesheetForm
            user={user}
            refreshTimesheets={refresh}
            defaultDate={activeDay}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            entryId={entryId}
            setEntryId={setEntryId}
          />
        )}
      </div>
    </Layout>
  );
};

export default Timesheet;
