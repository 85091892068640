import { Input, Modal, Spin, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import UserFinder from '../../chat/components/chat/userFinder';
import React from 'react';
import { useUsersBrief } from '../../dal';

interface Props {
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  onAddUserToChannel: React.Dispatch<React.SetStateAction<string[]>>;
  newChannelMembers;
  createNewChannel;
}

const AddChannelForm = ({ modalOpen, setModalOpen, onAddUserToChannel, createNewChannel, newChannelMembers }: Props) => {
  const [form] = useForm();

  const { users } = useUsersBrief({ activeOnly: true });

  const onFinish = async data => {
    const members = newChannelMembers && newChannelMembers.map(member => users && users.find(user => user.id === member.split('/')[1]));
    createNewChannel({ name: data.name, members: members });

    setModalOpen(false);
  };

  if (!users) return <Spin />;

  return (
    <Modal
      title="New channel"
      open={modalOpen}
      okText="Create channel"
      onOk={() => form.submit()}
      onCancel={() => setModalOpen(false)}
      className="channel-modal"
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item className="custom-form-block" label="Channel name" name="name" rules={[{ required: true, message: 'Channel name is required' }]}>
          <Input height="50px" placeholder="Enter channel name" />
        </Form.Item>
        <Form.Item className="custom-form-block" label="Add members" name="client" rules={[{ required: true, message: 'Mermbers is required' }]}>
          <UserFinder
            onChange={onAddUserToChannel}
            options={users
              // TODO: uncomment
              // .filter(user => user.id !== session?.id)
              .map(user => ({
                label: (
                  <span key={user.id}>
                    {/* <img className="my-[.2rem] w-[24px] h-[24px] rounded-full" src={user.picture} alt="" /> {user.name} */}
                    {user.email}
                  </span>
                ),
                value: `${user.name}/${user.id}`,
              }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddChannelForm;
