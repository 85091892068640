import { useEffect, useState, createRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import type { Project as IProject } from '../../../entities';
import ChatArea from '../../components/chat/chatArea';
import Layout from '../../layouts/chat/Chat';
import { DashboardPages, ISelectedPage } from './types/types';
import Project from '../../../pages/projects/[id]';
import DashboardSidebar from './components/DashboardSidebar';
import './index.scss';
import { NewChannelData, useChatApi } from '../../../api/useChatApi';
import { useSidebarWidth } from './hooks/useSidebarWidth';
import { useSession } from '../../../dal';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { RootState } from '../../../store/store';
import { useFeatureFlag } from 'configcat-react';
import { fetchChannels, createChannel } from '../../../store/reducers/channelsSlice';

const Dashboard = () => {
  const { id: idFromParams } = useParams<{ id: string }>();
  const location = useLocation();

  const pageTypeFromUrl = location.pathname.split('/')[1];

  const [selectedPage, setSelectedPage] = useState<ISelectedPage<any>>({ type: DashboardPages.INITIAL, data: null });
  const [isCreatingNewChannel, setIsCreatingNewChannel] = useState(false);
  const [newChannelMembers, setNewChannelMembers] = useState<string[]>([]);
  const [channelListOpen, setChannelListOpen] = useState<boolean>(true);

  const { session } = useSession();

  const { value: chatEnabled } = useFeatureFlag('chat', false, { identifier: session?.email! });

  const { id: channelId } = useParams();

  const { renameChannel } = useChatApi();

  const dispatch = useAppDispatch();

  const { channels } = useAppSelector((state: RootState) => state.channelReducer);

  useEffect(() => {
    if (session && chatEnabled) {
      dispatch(fetchChannels());
    }
  }, [chatEnabled, session, dispatch]);

  const DashboardSidebarRef = createRef<HTMLDivElement>();
  const DashboardMainContainerRef = createRef<HTMLDivElement>();
  const CustomDividerRef = createRef<HTMLDivElement>();

  const closeChannelList = () => {
    setNewChannelMembers([]);
    setIsCreatingNewChannel(false);
    setChannelListOpen(false);
  };

  useEffect(() => {
    if (idFromParams && pageTypeFromUrl === 'projects') {
      setSelectedPage({
        type: DashboardPages.PROJECT,
        data: {
          id: idFromParams,
        } as IProject,
      });
    }
  }, [idFromParams, pageTypeFromUrl]);

  const handleSideBarWidth = useSidebarWidth(DashboardSidebarRef, DashboardMainContainerRef, CustomDividerRef);

  useEffect(() => {
    handleSideBarWidth();
  }, [handleSideBarWidth]);

  const handleRenameChannel = async (name: string) => {
    const channelData = channels.find(chnl => chnl.id === channelId);

    if (name.length > 0 && channelData?.isNameEdited) {
      const result = await renameChannel(channelId, name);

      if (result.data?.data?.id) {
        channels[channels.findIndex(c => c.id === channelId)].name = name;
      }
    }
  };

  const createNewChannel = async (values: NewChannelData) => {
    const channelName = values.name || newChannelMembers[0].split('/')[0] + ' and other';
    dispatch(createChannel({ name: channelName, members: values.members }));
  };

  return (
    <Layout>
      <section className="dashboard-container flex w-full">
        <DashboardSidebar
          selectedPage={selectedPage}
          isCreatingNewChannel={isCreatingNewChannel}
          createNewChannel={createNewChannel}
          onSelectedPageChange={page => setSelectedPage(page)}
          onCreatingNewChannelChange={setIsCreatingNewChannel}
          onCloseChannelList={closeChannelList}
          ref={DashboardSidebarRef}
        />

        <div id="custom-divider-new" className="custom-divider" ref={CustomDividerRef}></div>
        <main id="dashboard-main-container" className="page-content flex-auto" ref={DashboardMainContainerRef}>
          {selectedPage.type === DashboardPages.CREATE && (
            <ChatArea
              type="nothing"
              isCreatingNewChannel={true}
              handleChannelListOpen={() => setChannelListOpen(!channelListOpen)}
              renameChannel={handleRenameChannel}
              channel={null}
              newChannelMembers={newChannelMembers}
              onAddUserToChannel={setNewChannelMembers}
            />
          )}

          {selectedPage.type === DashboardPages.PROJECT && <Project id={selectedPage.data.id} />}

          {selectedPage.type === DashboardPages.CHAT && selectedPage.data.type && (
            <ChatArea
              type={selectedPage.data.type}
              isCreatingNewChannel={isCreatingNewChannel}
              handleChannelListOpen={() => setChannelListOpen(!channelListOpen)}
              renameChannel={handleRenameChannel}
              channel={selectedPage.data}
              isAbleToRemoveUser={selectedPage.data.members?.length > 2}
              newChannelMembers={newChannelMembers}
              onAddUserToChannel={setNewChannelMembers}
              createNewChannel={createNewChannel}
            />
          )}
        </main>
      </section>
    </Layout>
  );
};

export default Dashboard;
