import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Space, Button, Spin, Statistic, Table, PageHeader, Typography, Modal, Select } from 'antd';
import moment from 'moment';
import Summary from './components/SummaryTable';
import { formatCurrency, nullableDataSorter } from '../../common/utils';
import { ProjectsReportItem } from '../../entities';
import { useTimesheetReportAllProjects } from '../../dal/useTimesheetReportAllProjects';
import TimesheetTabsControls from '../../components/Timesheet/TimesheetTabsControls';
import { useTimesheetReportByProjectCode } from '../../dal/useTimesheetReportByProjectCode';
import InvoiceModal from '../../components/project/ProjectFinance.invoiceModal';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ReportPermissions } from '../../common/usePermissions/permissions';
import { Link } from 'react-router-dom';
import { InvoiceStatusTag } from '../../components/project/InvoiceStatusTag';

const { Text } = Typography;

const FilterContext = createContext<{ filters: any; handleFilterChange: any }>({ filters: null, handleFilterChange: null });

const BudgetReport = () => {
  const { hasPermission } = usePermissions();
  const [activeDay, setActiveDay] = useState(moment().startOf('month'));
  const { allProjectsReport, mutate: allProjectsReportMutate } = useTimesheetReportAllProjects(activeDay.format('YYYY-MM-DD'));
  const [selectedProjectCode, setSelectedProjectCode] = useState<string | null>(null);
  const { projectReport } = useTimesheetReportByProjectCode(selectedProjectCode, activeDay.format('YYYY-MM-DD'));
  const [filters, setFilters] = useState({
    projectType: '',
    status: '',
    filteredProjectType: 'all',
  });

  const [openInvoice, setOpenInvoice] = useState<ProjectsReportItem | null>(null);

  const getPreviousMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().subtract(1, 'month'));
  }, []);

  const getNextMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().add(1, 'month'));
  }, []);

  const filteredProjectsReport = useMemo(() => {
    if (!allProjectsReport) {
      return;
    }

    return allProjectsReport.filter(report => {
      const matchProjectType = filters.projectType === '' || report.projectType === filters.projectType;

      const matchFilteredProjectType =
        filters.filteredProjectType === 'all' ||
        (filters.filteredProjectType === 'client' && !report?.isInternal) ||
        (filters.filteredProjectType === 'internal' && report?.isInternal);

      const matchStatus =
        filters.status === '' ||
        (filters.status === 'no-invoices' && !report?.invoiceId) ||
        (filters.status === 'invoices-only' && report?.invoiceId) ||
        (filters.status === 'paid' && report?.invoiceId && report?.paidDate) ||
        (filters.status === 'draft' && report?.invoiceId && !report?.isActive) ||
        (filters.status === 'overdue' && isOverdue(report)) ||
        (filters.status === 'sent' && isSent(report)) ||
        (filters.status === 'active' && isActive(report));

      return matchProjectType && matchFilteredProjectType && matchStatus;
    });
  }, [allProjectsReport, filters]);

  const precomputedInvoiceData = useMemo(() => {
    if (!filteredProjectsReport?.length) {
      return;
    }

    const projectData: Record<string, { total: number; markup: number }> = {};

    filteredProjectsReport.forEach(({ projectCode, invoiceValue, amountWCom }) => {
      if (!projectCode) {
        return;
      }

      const total = (projectData[projectCode]?.total || 0) + Number(invoiceValue);
      const markup = (1 - (amountWCom || 0) / (total || 1)) * 100;

      projectData[projectCode] = { total, markup };
    });

    return projectData;
  }, [filteredProjectsReport]);

  const handleFilterChange = useCallback((name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value || '',
    }));
  }, []);

  if (!allProjectsReport || !filteredProjectsReport) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  const totals = filteredProjectsReport?.reduce(
    (prev, curr) => ({
      totalAmount: prev.totalAmount + curr.amountWCom,
      totalProjectsLength: (prev.totalProjectsLength += 1),
      clientTotalAmount: !curr.isInternal ? prev.clientTotalAmount + curr.amountWCom : prev.clientTotalAmount,
      clientTotalProjectsLength: !curr.isInternal ? (prev.clientTotalProjectsLength += 1) : prev.clientTotalProjectsLength,
      internalTotalAmount: curr.isInternal ? prev.internalTotalAmount + curr.amountWCom : prev.internalTotalAmount,
      internalTotalProjectsLength: curr.isInternal ? (prev.internalTotalProjectsLength += 1) : prev.internalTotalProjectsLength,
    }),
    {
      totalAmount: 0,
      totalProjectsLength: 0,
      clientTotalAmount: 0,
      clientTotalProjectsLength: 0,
      internalTotalAmount: 0,
      internalTotalProjectsLength: 0,
    },
  );

  const invoiceTotals = filteredProjectsReport.reduce(
    (acc, curr) => ({
      totalAmount: acc.totalAmount + (curr.isActive ? Number(curr.invoiceValue) : 0),
    }),
    {
      totalAmount: 0,
    },
  );

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={activeDay.format('MMMM, YYYY')}
        className="payroll-header"
        subTitle={<TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />}
        extra={
          <FilterContext.Provider value={{ filters, handleFilterChange }}>
            <FilterControls />
          </FilterContext.Provider>
        }
      />

      <div className="ant-space-vertical">
        <Table
          bordered
          size="small"
          dataSource={filteredProjectsReport}
          rowKey={record => record.projectId + record.invoiceNumber}
          pagination={false}
          scroll={{ x: 1500 }}
          title={() => (
            <Space className="flex-wrap">
              {filters.filteredProjectType === 'all' && (
                <Statistic
                  title={`Total Spent (${totals.totalProjectsLength})`}
                  value={totals.totalAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}

              {['all', 'client'].includes(filters.filteredProjectType) && (
                <Statistic
                  title={`Client Total Spent (${totals.clientTotalProjectsLength})`}
                  value={totals.clientTotalAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}

              {['all', 'internal'].includes(filters.filteredProjectType) && (
                <Statistic
                  title={`Internal Total Spent (${totals.internalTotalProjectsLength})`}
                  value={totals.internalTotalAmount}
                  prefix="$"
                  precision={2}
                  style={{ width: '170px' }}
                />
              )}
              <Statistic
                title={`Total Billable Amount (${allProjectsReport.filter(report => report.invoiceId).length})`}
                value={invoiceTotals.totalAmount}
                prefix="$"
                precision={2}
                style={{ width: '170px' }}
              />
              {['all'].includes(filters.filteredProjectType) && (
                <Statistic
                  title="Monthly Profit (client and internal) %"
                  value={invoiceTotals.totalAmount > 0 ? (1 - totals.totalAmount / invoiceTotals.totalAmount) * 100 : 0}
                  suffix="%"
                  precision={2}
                  style={{ width: '170px' }}
                  valueStyle={{
                    color: getStatisticsProfitColor(invoiceTotals.totalAmount > 0 ? (1 - totals.totalAmount / invoiceTotals.totalAmount) * 100 : 0),
                  }}
                />
              )}
              {['all', 'client'].includes(filters.filteredProjectType) && (
                <Statistic
                  title="Monthy Client Profit %"
                  value={invoiceTotals.totalAmount > 0 ? (1 - totals.clientTotalAmount / invoiceTotals.totalAmount) * 100 : 0}
                  suffix="%"
                  precision={2}
                  style={{ width: '170px' }}
                  valueStyle={{
                    color: getStatisticsProfitColor(invoiceTotals.totalAmount > 0 ? (1 - totals.totalAmount / invoiceTotals.totalAmount) * 100 : 0),
                  }}
                />
              )}
            </Space>
          )}
        >
          <Table.Column
            dataIndex="clientName"
            title="Client"
            width={200}
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/clients/${row.clientId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.clientName, b.clientName)}
          />
          <Table.Column
            dataIndex="projectName"
            width={300}
            title="Name"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.projectName, b.projectName)}
          />
          <Table.Column
            dataIndex="projectCode"
            title="Code"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) =>
              nullableDataSorter(parseInt(a.projectCode.substring(1), 10), parseInt(b.projectCode.substring(1), 10))
            }
          />
          <Table.Column
            dataIndex="projectType"
            title="Type"
            width={200}
            render={(value: string) => typeOptions.find(option => option.value === value)?.label}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.projectType, b.projectType)}
          />
          <Table.Column
            title="Timesheet"
            dataIndex="projectCode"
            render={(value: string, row: ProjectsReportItem) =>
              row.amountWCom ? (
                <Button type="link" onClick={() => setSelectedProjectCode(value)} disabled={!hasPermission(ReportPermissions.REPORTS_TIMESHEET_READ)}>
                  Show
                </Button>
              ) : (
                <Text type="secondary">No entries</Text>
              )
            }
          />
          <Table.Column
            dataIndex="allTimeProfit"
            title={
              <>
                Project all time profit % <Text type="danger">(ex com)</Text>
              </>
            }
            align="right"
            width={150}
            render={allTimeProfit => <Text type={getProfitColor(allTimeProfit)}>{allTimeProfit.toFixed(2)}%</Text>}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.allTimeProfit, b.allTimeProfit)}
          />
          <Table.Column
            dataIndex="amountWCom"
            title="Project internal spend"
            align="right"
            width={100}
            render={formatCurrency}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.amountWCom, b.amountWCom)}
          />
          <Table.Column
            dataIndex="amountExCom"
            title={
              <>
                Project internal spend <Text type="danger">(ex com)</Text>
              </>
            }
            align="right"
            width={100}
            render={formatCurrency}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => nullableDataSorter(a.amountExCom, b.amountExCom)}
          />
          <Table.Column
            dataIndex="projectCode"
            title="Project billable amount"
            align="right"
            width={100}
            render={projectCode => {
              const total = precomputedInvoiceData?.[projectCode]?.total || 0;
              return formatCurrency(total);
            }}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => {
              const totalA = precomputedInvoiceData?.[a.projectCode]?.total || 0;
              const totalB = precomputedInvoiceData?.[b.projectCode]?.total || 0;
              return totalA - totalB;
            }}
          />
          <Table.Column
            dataIndex="projectCode"
            title={
              <>
                Project monthly profit % <Text type="danger">(ex com)</Text>
              </>
            }
            width={100}
            align="right"
            render={projectCode => {
              const markupPercentage = precomputedInvoiceData?.[projectCode]?.markup || 0;

              return <Text type={getProfitColor(markupPercentage)}>{markupPercentage.toFixed(2)}%</Text>;
            }}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => {
              const markupA = precomputedInvoiceData?.[a.projectCode]?.markup || 0;
              const markupB = precomputedInvoiceData?.[b.projectCode]?.markup || 0;
              return markupA - markupB;
            }}
          />
          <Table.Column
            dataIndex="invoiceNumber"
            title="Invoice #"
            width={100}
            render={(invoiceNumber, row: ProjectsReportItem) =>
              invoiceNumber && (
                <Button type="link" onClick={() => setOpenInvoice(row)} style={{ padding: 0 }}>
                  {invoiceNumber}
                </Button>
              )
            }
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => (a?.invoiceNumber || '').localeCompare(b?.invoiceNumber || '')}
          />
          <Table.Column
            dataIndex="due"
            title="Bill for"
            width={200}
            align="right"
            render={(due: string) => due && <Text>{due}</Text>}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => (a?.due || '').localeCompare(b?.due || '')}
          />
          <Table.Column
            dataIndex="isActive"
            title="Status"
            width={100}
            align="center"
            render={(_, reportItem: ProjectsReportItem) => reportItem.invoiceNumber && <InvoiceStatusTag invoice={reportItem} />}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => {
              const aStatus = getStatusRank(a);
              const bStatus = getStatusRank(b);

              return aStatus - bStatus;
            }}
          />
          <Table.Column
            dataIndex="invoiceValue"
            title="Invoice billable amount"
            width={100}
            align="right"
            render={invoiceValue => invoiceValue && formatCurrency(invoiceValue)}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => {
              const billableAmountA = a?.invoiceValue || 0;
              const billableAmountB = b?.invoiceValue || 0;
              return billableAmountA - billableAmountB;
            }}
          />
          <Table.Column
            dataIndex="actualCost"
            title={
              <>
                Invoice internal spend % <Text type="danger">(ex com)</Text>
              </>
            }
            width={100}
            align="right"
            render={actualCost => actualCost && formatCurrency(actualCost)}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => {
              const internalSpentA = a?.actualCost || 0;
              const internalSpentB = b?.actualCost || 0;
              return internalSpentA - internalSpentB;
            }}
          />
          <Table.Column
            dataIndex="markupPercentage"
            title={
              <>
                Invoice profit % <Text type="danger">(ex com)</Text>
              </>
            }
            width={100}
            align="right"
            render={(markupPercentage: number, row: ProjectsReportItem) => {
              if (Number(markupPercentage)) {
                return <Text type={getProfitColor(markupPercentage)}>{markupPercentage}%</Text>;
              }

              const { actualCost, invoiceValue } = row;

              if (invoiceValue) {
                const calculatedMarkup = (1 - (actualCost || 0) / (Number(invoiceValue) || 1)) * 100;

                return <Text type={getProfitColor(calculatedMarkup)}>{calculatedMarkup.toFixed(2)}%</Text>;
              }

              return null;
            }}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => {
              const markupPercentageA = a?.markupPercentage || 0;
              const markupPercentagB = b?.markupPercentage || 0;
              return markupPercentageA - markupPercentagB;
            }}
          />
          <Table.Column
            dataIndex="description"
            title="Internal note"
            width={100}
            align="right"
            render={(description: string) => description && <Text>{description}</Text>}
            sorter={(a: ProjectsReportItem, b: ProjectsReportItem) => (a?.description || '').localeCompare(b?.description || '')}
          />
        </Table>
      </div>

      {openInvoice && (
        <InvoiceModal
          project={{
            id: openInvoice.projectId || '',
            name: openInvoice.projectName || '',
            autoCode: openInvoice.invoiceNumber || '',
            client: { name: openInvoice.clientName || '' },
          }}
          invoiceId={openInvoice.invoiceId || ''}
          onCancel={() => {
            setOpenInvoice(null);
          }}
          onSubmit={() => {
            setOpenInvoice(null);
            allProjectsReportMutate();
          }}
        />
      )}

      <Modal
        title={`Timesheet report for ${
          projectReport?.length ? `${projectReport[0].clientName} - ${projectReport[0].projectName} - ${projectReport[0].projectCode}` : ''
        }`}
        open={Boolean(projectReport?.length)}
        width={1200}
        destroyOnClose
        footer={null}
        onCancel={() => setSelectedProjectCode(null)}
      >
        <Table bordered size="small" dataSource={projectReport} summary={Summary} pagination={false}>
          <Table.Column dataIndex="contactName" title="Employee" />
          <Table.Column dataIndex="category" title="Category" />
          <Table.Column
            dataIndex="rate"
            title="Rate"
            align="right"
            render={(value, row: ProjectsReportItem) => (row.fixedCost !== 0 ? '' : formatCurrency(value))}
          />
          <Table.Column
            dataIndex="hours"
            title="Hours"
            align="right"
            render={(value, row: ProjectsReportItem) => (row.fixedCost !== 0 ? '' : value.toFixed(2))}
          />
          <Table.Column
            dataIndex="amount"
            title="Amount"
            align="right"
            render={(amount, row: ProjectsReportItem) => formatCurrency(row.fixedCost !== 0 ? row.fixedCost : amount)}
          />
        </Table>
      </Modal>
    </div>
  );
};

export default BudgetReport;

const FilterControls = () => {
  const { filters, handleFilterChange } = useContext(FilterContext);

  return (
    <Space>
      <Select
        allowClear
        placeholder="Filter by type"
        value={filters.projectType}
        onChange={value => handleFilterChange('projectType', value)}
        style={{ width: '150px' }}
        options={typeOptions}
        getPopupContainer={trigger => trigger.parentElement}
      />

      <Select
        allowClear
        placeholder="Filter by status"
        value={filters.status}
        onChange={value => handleFilterChange('status', value)}
        style={{ width: '150px' }}
        options={statusOptions}
      />

      <Select
        placeholder="Filter by project type"
        value={filters.filteredProjectType}
        onChange={value => handleFilterChange('filteredProjectType', value)}
        style={{ width: '150px' }}
        options={projectTypeOptions}
      />
    </Space>
  );
};

const getStatusRank = (invoice: ProjectsReportItem | null): number => {
  if (!invoice) {
    return 0;
  }
  if (!invoice.isActive) {
    return 1;
  }
  if (invoice.paidDate) {
    return 4;
  }
  const isOverdue = moment(invoice.estimatedPayDate).isBefore(moment());

  return isOverdue ? 3 : 2;
};

const getProfitColor = (markupPercentage: number) => {
  if (markupPercentage > 66) {
    return 'success';
  } else if (markupPercentage > 60) {
    return 'warning';
  } else {
    return 'danger';
  }
};

export const getStatisticsProfitColor = (markupPercentage: number) => {
  if (markupPercentage > 66) {
    return '#52c41a';
  } else if (markupPercentage > 60) {
    return '#faad14';
  } else {
    return '#f5222d';
  }
};

const typeOptions = [
  { value: 'time-and-material', label: 'Time and Material' },
  { value: 'subscription', label: 'Subscription' },
  { value: 'fixed', label: 'Fixed' },
];

const statusOptions = [
  { value: 'no-invoices', label: 'No invoices' },
  { value: 'invoices-only', label: 'Invoices only' },
  { value: 'paid', label: 'Paid' },
  { value: 'sent', label: 'Sent' },
  { value: 'active', label: 'Active' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'draft', label: 'Draft' },
];

const projectTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'internal', label: 'Internal' },
  { value: 'client', label: 'Client' },
];

const isOverdue = (report: ProjectsReportItem) =>
  report.invoiceId && moment(report.estimatedPayDate).isBefore(moment()) && report.isActive && !report.paidDate;

const isSent = (report: ProjectsReportItem) =>
  report.invoiceId && report.sentDate && report.isActive && !moment(report.estimatedPayDate).isBefore(moment());

const isActive = (report: ProjectsReportItem) =>
  report.isActive && !report.paidDate && !report.sentDate && !moment(report.estimatedPayDate).isBefore(moment());
