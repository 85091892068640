import { useRef, useState } from 'react';
import { Avatar, Button, Mentions, Popover, Space, Typography, Upload, UploadProps, message } from 'antd';
import { SmileOutlined, SendOutlined, FileAddOutlined } from '@ant-design/icons';
import EmojiPicker from 'emoji-picker-react';
import { useAppDispatch } from '../../../hooks/redux';
import { useProject, UserBrief } from '../../../dal';
import { createChannel } from '../../../store/reducers/channelsSlice';
import { createMessage } from '../../../store/reducers/messagesSlice';
import { axiosBackend } from '../../../api/backend';

interface ReplyFormProps {
  users: UserBrief[];
  channelId?: string;
  projectId?: string;
  projectCode?: string;
}

const Editor = ({ channelId, projectId, projectCode, users }: ReplyFormProps) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const [mentionOpen, setMentionOpen] = useState(false);
  const mentionsRef = useRef<any>(null);
  const { mutate: mutateProject } = useProject(projectId || '');

  const mentionOptions = users.map(user => ({
    value: user.name,
    label: (
      <Space>
        <Avatar src={user.picture} style={{ marginRight: '6px' }} size="small" />
        <Typography.Text>{user.name}</Typography.Text>
      </Space>
    ),
  }));

  const handleEmojiClick = emojiObject => {
    if (!mentionsRef.current) {
      return;
    }

    setValue(prev => prev + emojiObject.emoji);
    mentionsRef.current.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (mentionOpen) return;
      e.preventDefault();
      handleSubmit();
    }
    if (e.key === 'Escape') {
      setMentionOpen(false);
    }
  };

  const handleSubmit = async () => {
    const trimmedValue = value.trim();
    if (!trimmedValue) return;

    try {
      const finalChannelId =
        channelId || (await dispatch(createChannel({ name: projectCode || '', type: 'project_internal', projectId })).unwrap()).id;

      await mutateProject();
      await dispatch(createMessage({ channelId: finalChannelId, text: trimmedValue })).unwrap();
      setValue('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    fileList: [],
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        const formData = new FormData();
        formData.append('file', file as File);

        const response = await axiosBackend.post(`/chat/files`, formData);
        const { data } = response;

        if (data.fileUrl) {
          setValue(prev => prev + ' ' + data.fileUrl);
          message.success(`${(file as File).name} uploaded successfully`);
          onSuccess?.('ok');
        }
      } catch (error) {
        message.error(`Upload failed ${(file as File).name}`);
        console.error('Upload failed:', error);
        onError?.(new Error('Upload failed'));
      }
    },
  };

  return (
    <div className="editor-container" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <Mentions
        ref={mentionsRef}
        value={value}
        options={mentionOptions}
        split="|"
        onChange={setValue}
        placeholder="Enter your message..."
        autoSize={{ minRows: 2, maxRows: 6 }}
        onKeyDown={handleKeyDown}
        onSelect={() => setMentionOpen(false)}
        onFocus={() => setMentionOpen(false)}
        onSearch={(_, prefix) => setMentionOpen(prefix === '@')}
      />

      <Popover content={<EmojiPicker onEmojiClick={handleEmojiClick} />} placement="topRight" trigger="click">
        <Button shape="circle" icon={<SmileOutlined style={{ fontSize: 25 }} />} size="large" />
      </Popover>

      <Upload {...uploadProps}>
        <Button shape="circle" icon={<FileAddOutlined style={{ fontSize: 25 }} />} size="large" />
      </Upload>

      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<SendOutlined style={{ fontSize: 25 }} />}
        onClick={handleSubmit}
        disabled={!value.trim()}
      />
    </div>
  );
};

export default Editor;
