import { Select, Space, PageHeader, Switch, Spin } from 'antd';
import TimesheetTabs from '../Timesheet/TimesheetTabs';
import TimesheetTabsControls from '../Timesheet/TimesheetTabsControls';
import { useState } from 'react';
import { useTimesheetData } from '../../pages/timesheet/useTimesheetData';
import Title from 'antd/lib/typography/Title';
import { formatDateTime, getLastSaturday, getFirstDayOfMonth } from '../../common/utils';
import TimesheetMonth from '../Timesheet/TimesheetMonth';
import moment from 'moment';
import { useTimesheetModal } from '../Timesheet/useTimesheetModal';
import TimesheetForm from '../Timesheet/TimesheetForm';
import { ApprovalsPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useUserProjects } from '../../dal/useUserProjects';

const { Option } = Select;

interface Props {
  userId: number | string;
}

const UserTimesheet = ({ userId }: Props) => {
  const { hasPermission } = usePermissions();
  const { projects } = useUserProjects(`${userId}`);
  const [projectId, setProjectId] = useState<string>('all');
  const [monthlyView, setMontlyView] = useState(false);
  const {
    getNextWeek,
    getPreviousWeek,
    getPreviousMonth,
    getNextMonth,
    userTimesheet,
    userTimesheetLoading,
    activeDay,
    setActiveDay,
    setStartDate,
    weekDays,
    user,
    refresh,
  } = useTimesheetData({
    userId,
    projectId,
    monthlyView,
  });
  const { entryId, modalOpen, openModal, setModalOpen, setEntryId } = useTimesheetModal();

  const setFilter = (value: string) => {
    setProjectId(value === 'all' ? 'all' : value);
  };

  const dayChange = (date: string) => {
    setActiveDay(date);
  };

  const monthlyViewChange = (checked: boolean) => {
    setMontlyView(checked);
    setStartDate(checked ? getFirstDayOfMonth() : getLastSaturday());
    setActiveDay(moment(checked ? getFirstDayOfMonth() : new Date()).format('MMM D, YYYY'));
  };

  return (
    <>
      <PageHeader
        title="Timesheet"
        tags={
          <div>
            <Switch checked={monthlyView} checkedChildren="Monthly" unCheckedChildren="Monthly" onChange={checked => monthlyViewChange(checked)} />
          </div>
        }
      >
        {projects && (
          <Space size={20}>
            <Select
              showSearch
              placeholder="Select project"
              onChange={setFilter}
              style={{ width: '250px' }}
              filterOption={(input, option) => {
                if (!option) return false;
                return `${option.children?.toString()}`.replaceAll(',', '').toLowerCase().indexOf(input.toLowerCase()) > -1;
              }}
            >
              <Option key="all" value="all">
                All
              </Option>
              {projects
                .filter(member => member.project)
                .map(member => (
                  <Option key={member.project.id} value={member.project.id}>
                    {member.project.client?.name || <i>No client</i>}- {member.project.name}
                  </Option>
                ))}
            </Select>
          </Space>
        )}

        {userTimesheetLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Spin size="large" />
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Title level={5}>
              {monthlyView
                ? formatDateTime(activeDay, 'MMMM, YYYY')
                : `${formatDateTime(weekDays[0], 'D MMM')} - ${formatDateTime(weekDays[6], 'D MMM')}`}
            </Title>
            <Space>
              {monthlyView ? (
                <TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />
              ) : (
                <TimesheetTabsControls onGetPrevious={getPreviousWeek} onGetNext={getNextWeek} />
              )}
            </Space>
          </div>
        )}
      </PageHeader>
      {userTimesheet &&
        (monthlyView ? (
          <TimesheetMonth timesheets={userTimesheet} rates={user} activeDay={activeDay} />
        ) : (
          <TimesheetTabs
            displayFinancialData={true}
            addEntryEnabled={hasPermission(ApprovalsPermissions.APPROVALS_WRITE)}
            user={user}
            activeDay={activeDay}
            onDayChange={dayChange}
            timesheets={userTimesheet}
            days={weekDays}
            onEditClick={hasPermission(ApprovalsPermissions.APPROVALS_WRITE) ? openModal : undefined}
            refreshTimesheets={refresh}
          />
        ))}
      {modalOpen && entryId && (
        <TimesheetForm
          user={user}
          defaultDate={activeDay}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          entryId={entryId}
          setEntryId={setEntryId}
          refreshTimesheets={refresh}
        />
      )}
    </>
  );
};

export default UserTimesheet;
